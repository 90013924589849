import { useSelector } from "react-redux"
import { loadedClient } from "../../redux/initializerFunctions"
import decrypt from "../security/decrypt"

const useClientInfo = () => {
const clientStartingPoint = useSelector(state=> state.clientStartingPoint)
const clientDecrypted = useSelector(state=> state.clientDecrypt)
const checkClientDecrypt = clientDecrypted?.client === 'N/A'? {client:loadedClient().currentClient} : clientDecrypted
const checkClientStarting = Object.keys(clientStartingPoint).length === 0 ? decrypt() : clientStartingPoint
return {clientDecryptSignal:{value: checkClientDecrypt}, clientStartingPoint:{value:checkClientStarting}}
}

export {useClientInfo}
import { createContext, useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types'
import { GoogleAuthProvider, signInWithPopup, onAuthStateChanged, signOut } from 'firebase/auth'
import { googleAuth } from '../firebase-google-config'

const GoogleAuthContext = createContext({
  currentUser: null,
  signInWithGoogle: () => Promise,
  googleLogout: () => Promise,
})

export const useGoogleAuth = () => useContext(GoogleAuthContext)

export default function GoogleAuthContextProvider({ children }) {
  const [currentUser, setCurrentUser] = useState(null)

  function signInWithGoogle() {
    const googleProvider = new GoogleAuthProvider()
    return signInWithPopup(googleAuth, googleProvider)
  }

  function googleLogout() {
    return signOut(googleAuth)
  }

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(googleAuth, (user) => {
      setCurrentUser(user)
    })
    return () => {
      unsubscribe()
    }
  }, [])

  const value = {
    currentUser,
    signInWithGoogle,
    googleLogout,
  }

  return <GoogleAuthContext.Provider value={value}>{children}</GoogleAuthContext.Provider>
}

GoogleAuthContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
}

export const getZoom = (radius, grid) => {
  switch (grid) {
    case '13x13':
      switch (radius) {
        case '.25mi':
          return 12
        case '.5mi':
          return 11
        case '1mi':
          return 10
      }
      break
    case '9x9':
      switch (radius) {
        case '.25mi':
          return 13
        case '.5mi':
          return 12
        case '1mi':
          return 11
      }
      break
    case '7x7':
      switch (radius) {
        case '.25mi':
          return 13
        case '.5mi':
          return 12
        case '1mi':
          return 11
      }
      break
    case '5x5':
      switch (radius) {
        case '.25mi':
          return 14
        case '.5mi':
          return 13
        case '1mi':
          return 12
      }
      break
    default:
      switch (radius) {
        case '.25mi':
          return 15
        case '.5mi':
          return 14
        case '1mi':
          return 13
      }
  }
}

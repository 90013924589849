const radiusCalc = {
  '.25mi': 0.402336,
  '.5mi': 0.804672,
  '1mi': 1.60934,
}

const gridCalc = {
  '7x7': 7,
  '9x9': 9,
  '13x13': 13,
}

const addendCalc = {
  '7x7': {
    '7x7': 0,
    '9x9': 1,
    '13x13': 3,
  },
  '9x9': {
    '9x9': 0,
    '13x13': 2,
  },
}

const getPoint = (lat, lng, radius, bearing, row, index, addend) => {
  const R = 6378.1 // Radius of the Earth in km

  const brng = (bearing * Math.PI) / 180 // Bearing is degrees converted to radians.
  const d = radius // Distance in km

  const lat1 = (lat * Math.PI) / 180 // Current lat point converted to radians
  const lon1 = (lng * Math.PI) / 180 // Current long point converted to radians

  const lat2 = Math.asin(
    Math.sin(lat1) * Math.cos(d / R) + Math.cos(lat1) * Math.sin(d / R) * Math.cos(brng),
  )
  const lon2 =
    lon1 +
    Math.atan2(
      Math.sin(brng) * Math.sin(d / R) * Math.cos(lat1),
      Math.cos(d / R) - Math.sin(lat1) * Math.sin(lat2),
    )

  // Convert back to degrees
  const lat2d = (lat2 * 180) / Math.PI
  const lon2d = (lon2 * 180) / Math.PI

  return { lat: lat2d, lng: lon2d, custom_id: `Row_${row + addend}-${index + addend}` }
}

export const generateLocations = (lat, lng, radius, grid) => {
  const locations = []
  const cardinals = {
    N3: getPoint(lat, lng, radiusCalc[radius], 0),
    S3: getPoint(lat, lng, radiusCalc[radius], 180),
    N5: getPoint(lat, lng, radiusCalc[radius] * 2, 0),
    S5: getPoint(lat, lng, radiusCalc[radius] * 2, 180),
    N7: getPoint(lat, lng, radiusCalc[radius] * 3, 0),
    S7: getPoint(lat, lng, radiusCalc[radius] * 3, 180),
    N9: getPoint(lat, lng, radiusCalc[radius] * 4, 0),
    S9: getPoint(lat, lng, radiusCalc[radius] * 4, 180),
    N11: getPoint(lat, lng, radiusCalc[radius] * 5, 0),
    S11: getPoint(lat, lng, radiusCalc[radius] * 5, 180),
    N13: getPoint(lat, lng, radiusCalc[radius] * 6, 0),
    S13: getPoint(lat, lng, radiusCalc[radius] * 6, 180),
  }

  // 7x7 Grid
  locations.push(
    {
      lat,
      lng,
      custom_id: `${gridCalc[grid] > 9 ? 'Row_7-7' : gridCalc[grid] > 7 ? 'Row_5-5' : 'Row_4-4'}`,
    },
    getPoint(lat, lng, radiusCalc[radius], 0, 3, 4, addendCalc['7x7'][grid]),
    getPoint(lat, lng, radiusCalc[radius], 90, 4, 5, addendCalc['7x7'][grid]),
    getPoint(lat, lng, radiusCalc[radius], 180, 5, 4, addendCalc['7x7'][grid]),
    getPoint(lat, lng, radiusCalc[radius], 270, 4, 3, addendCalc['7x7'][grid]),
    getPoint(
      cardinals.N3.lat,
      cardinals.N3.lng,
      radiusCalc[radius],
      90,
      3,
      5,
      addendCalc['7x7'][grid],
    ),
    getPoint(
      cardinals.N3.lat,
      cardinals.N3.lng,
      radiusCalc[radius],
      270,
      3,
      3,
      addendCalc['7x7'][grid],
    ),
    getPoint(
      cardinals.S3.lat,
      cardinals.S3.lng,
      radiusCalc[radius],
      90,
      5,
      5,
      addendCalc['7x7'][grid],
    ),
    getPoint(
      cardinals.S3.lat,
      cardinals.S3.lng,
      radiusCalc[radius],
      270,
      5,
      3,
      addendCalc['7x7'][grid],
    ),
    getPoint(lat, lng, radiusCalc[radius] * 2, 0, 2, 4, addendCalc['7x7'][grid]),
    getPoint(lat, lng, radiusCalc[radius] * 2, 90, 4, 6, addendCalc['7x7'][grid]),
    getPoint(lat, lng, radiusCalc[radius] * 2, 180, 6, 4, addendCalc['7x7'][grid]),
    getPoint(lat, lng, radiusCalc[radius] * 2, 270, 4, 2, addendCalc['7x7'][grid]),
    getPoint(
      cardinals.N3.lat,
      cardinals.N3.lng,
      radiusCalc[radius] * 2,
      90,
      3,
      6,
      addendCalc['7x7'][grid],
    ),
    getPoint(
      cardinals.N3.lat,
      cardinals.N3.lng,
      radiusCalc[radius] * 2,
      270,
      3,
      2,
      addendCalc['7x7'][grid],
    ),
    getPoint(
      cardinals.N5.lat,
      cardinals.N5.lng,
      radiusCalc[radius],
      90,
      2,
      5,
      addendCalc['7x7'][grid],
    ),
    getPoint(
      cardinals.N5.lat,
      cardinals.N5.lng,
      radiusCalc[radius],
      270,
      2,
      3,
      addendCalc['7x7'][grid],
    ),
    getPoint(
      cardinals.N5.lat,
      cardinals.N5.lng,
      radiusCalc[radius] * 2,
      90,
      2,
      6,
      addendCalc['7x7'][grid],
    ),
    getPoint(
      cardinals.N5.lat,
      cardinals.N5.lng,
      radiusCalc[radius] * 2,
      270,
      2,
      2,
      addendCalc['7x7'][grid],
    ),
    getPoint(
      cardinals.S3.lat,
      cardinals.S3.lng,
      radiusCalc[radius] * 2,
      90,
      5,
      6,
      addendCalc['7x7'][grid],
    ),
    getPoint(
      cardinals.S3.lat,
      cardinals.S3.lng,
      radiusCalc[radius] * 2,
      270,
      5,
      2,
      addendCalc['7x7'][grid],
    ),
    getPoint(
      cardinals.S5.lat,
      cardinals.S5.lng,
      radiusCalc[radius],
      90,
      6,
      5,
      addendCalc['7x7'][grid],
    ),
    getPoint(
      cardinals.S5.lat,
      cardinals.S5.lng,
      radiusCalc[radius],
      270,
      6,
      3,
      addendCalc['7x7'][grid],
    ),
    getPoint(
      cardinals.S5.lat,
      cardinals.S5.lng,
      radiusCalc[radius] * 2,
      90,
      6,
      6,
      addendCalc['7x7'][grid],
    ),
    getPoint(
      cardinals.S5.lat,
      cardinals.S5.lng,
      radiusCalc[radius] * 2,
      270,
      6,
      2,
      addendCalc['7x7'][grid],
    ),
    getPoint(lat, lng, radiusCalc[radius] * 3, 0, 1, 4, addendCalc['7x7'][grid]),
    getPoint(lat, lng, radiusCalc[radius] * 3, 90, 4, 7, addendCalc['7x7'][grid]),
    getPoint(lat, lng, radiusCalc[radius] * 3, 180, 7, 4, addendCalc['7x7'][grid]),
    getPoint(lat, lng, radiusCalc[radius] * 3, 270, 4, 1, addendCalc['7x7'][grid]),
    getPoint(
      cardinals.N3.lat,
      cardinals.N3.lng,
      radiusCalc[radius] * 3,
      90,
      3,
      7,
      addendCalc['7x7'][grid],
    ),
    getPoint(
      cardinals.N3.lat,
      cardinals.N3.lng,
      radiusCalc[radius] * 3,
      270,
      3,
      1,
      addendCalc['7x7'][grid],
    ),
    getPoint(
      cardinals.N5.lat,
      cardinals.N5.lng,
      radiusCalc[radius] * 3,
      90,
      2,
      7,
      addendCalc['7x7'][grid],
    ),
    getPoint(
      cardinals.N5.lat,
      cardinals.N5.lng,
      radiusCalc[radius] * 3,
      270,
      2,
      1,
      addendCalc['7x7'][grid],
    ),
    getPoint(
      cardinals.N7.lat,
      cardinals.N7.lng,
      radiusCalc[radius],
      90,
      1,
      5,
      addendCalc['7x7'][grid],
    ),
    getPoint(
      cardinals.N7.lat,
      cardinals.N7.lng,
      radiusCalc[radius],
      270,
      1,
      3,
      addendCalc['7x7'][grid],
    ),
    getPoint(
      cardinals.N7.lat,
      cardinals.N7.lng,
      radiusCalc[radius] * 2,
      90,
      1,
      6,
      addendCalc['7x7'][grid],
    ),
    getPoint(
      cardinals.N7.lat,
      cardinals.N7.lng,
      radiusCalc[radius] * 2,
      270,
      1,
      2,
      addendCalc['7x7'][grid],
    ),
    getPoint(
      cardinals.N7.lat,
      cardinals.N7.lng,
      radiusCalc[radius] * 3,
      90,
      1,
      7,
      addendCalc['7x7'][grid],
    ),
    getPoint(
      cardinals.N7.lat,
      cardinals.N7.lng,
      radiusCalc[radius] * 3,
      270,
      1,
      1,
      addendCalc['7x7'][grid],
    ),
    getPoint(
      cardinals.S3.lat,
      cardinals.S3.lng,
      radiusCalc[radius] * 3,
      90,
      5,
      7,
      addendCalc['7x7'][grid],
    ),
    getPoint(
      cardinals.S3.lat,
      cardinals.S3.lng,
      radiusCalc[radius] * 3,
      270,
      5,
      1,
      addendCalc['7x7'][grid],
    ),
    getPoint(
      cardinals.S5.lat,
      cardinals.S5.lng,
      radiusCalc[radius] * 3,
      90,
      6,
      7,
      addendCalc['7x7'][grid],
    ),
    getPoint(
      cardinals.S5.lat,
      cardinals.S5.lng,
      radiusCalc[radius] * 3,
      270,
      6,
      1,
      addendCalc['7x7'][grid],
    ),
    getPoint(
      cardinals.S7.lat,
      cardinals.S7.lng,
      radiusCalc[radius],
      90,
      7,
      5,
      addendCalc['7x7'][grid],
    ),
    getPoint(
      cardinals.S7.lat,
      cardinals.S7.lng,
      radiusCalc[radius],
      270,
      7,
      3,
      addendCalc['7x7'][grid],
    ),
    getPoint(
      cardinals.S7.lat,
      cardinals.S7.lng,
      radiusCalc[radius] * 2,
      90,
      7,
      6,
      addendCalc['7x7'][grid],
    ),
    getPoint(
      cardinals.S7.lat,
      cardinals.S7.lng,
      radiusCalc[radius] * 2,
      270,
      7,
      2,
      addendCalc['7x7'][grid],
    ),
    getPoint(
      cardinals.S7.lat,
      cardinals.S7.lng,
      radiusCalc[radius] * 3,
      90,
      7,
      7,
      addendCalc['7x7'][grid],
    ),
    getPoint(
      cardinals.S7.lat,
      cardinals.S7.lng,
      radiusCalc[radius] * 3,
      270,
      7,
      1,
      addendCalc['7x7'][grid],
    ),
  )
  // 9x9 Grid
  if (gridCalc[grid] > 7) {
    locations.push(
      getPoint(lat, lng, radiusCalc[radius] * 4, 0, 1, 5, addendCalc['9x9'][grid]),
      getPoint(lat, lng, radiusCalc[radius] * 4, 90, 5, 9, addendCalc['9x9'][grid]),
      getPoint(lat, lng, radiusCalc[radius] * 4, 180, 9, 5, addendCalc['9x9'][grid]),
      getPoint(lat, lng, radiusCalc[radius] * 4, 270, 5, 1, addendCalc['9x9'][grid]),
      getPoint(
        cardinals.N3.lat,
        cardinals.N3.lng,
        radiusCalc[radius] * 4,
        90,
        4,
        9,
        addendCalc['9x9'][grid],
      ),
      getPoint(
        cardinals.N3.lat,
        cardinals.N3.lng,
        radiusCalc[radius] * 4,
        270,
        4,
        1,
        addendCalc['9x9'][grid],
      ),
      getPoint(
        cardinals.N5.lat,
        cardinals.N5.lng,
        radiusCalc[radius] * 4,
        90,
        3,
        9,
        addendCalc['9x9'][grid],
      ),
      getPoint(
        cardinals.N5.lat,
        cardinals.N5.lng,
        radiusCalc[radius] * 4,
        270,
        3,
        1,
        addendCalc['9x9'][grid],
      ),
      getPoint(
        cardinals.N7.lat,
        cardinals.N7.lng,
        radiusCalc[radius] * 4,
        90,
        2,
        9,
        addendCalc['9x9'][grid],
      ),
      getPoint(
        cardinals.N7.lat,
        cardinals.N7.lng,
        radiusCalc[radius] * 4,
        270,
        2,
        1,
        addendCalc['9x9'][grid],
      ),
      getPoint(
        cardinals.N9.lat,
        cardinals.N9.lng,
        radiusCalc[radius],
        90,
        1,
        6,
        addendCalc['9x9'][grid],
      ),
      getPoint(
        cardinals.N9.lat,
        cardinals.N9.lng,
        radiusCalc[radius],
        270,
        1,
        4,
        addendCalc['9x9'][grid],
      ),
      getPoint(
        cardinals.N9.lat,
        cardinals.N9.lng,
        radiusCalc[radius] * 2,
        90,
        1,
        7,
        addendCalc['9x9'][grid],
      ),
      getPoint(
        cardinals.N9.lat,
        cardinals.N9.lng,
        radiusCalc[radius] * 2,
        270,
        1,
        3,
        addendCalc['9x9'][grid],
      ),
      getPoint(
        cardinals.N9.lat,
        cardinals.N9.lng,
        radiusCalc[radius] * 3,
        90,
        1,
        8,
        addendCalc['9x9'][grid],
      ),
      getPoint(
        cardinals.N9.lat,
        cardinals.N9.lng,
        radiusCalc[radius] * 3,
        270,
        1,
        2,
        addendCalc['9x9'][grid],
      ),
      getPoint(
        cardinals.N9.lat,
        cardinals.N9.lng,
        radiusCalc[radius] * 4,
        90,
        1,
        9,
        addendCalc['9x9'][grid],
      ),
      getPoint(
        cardinals.N9.lat,
        cardinals.N9.lng,
        radiusCalc[radius] * 4,
        270,
        1,
        1,
        addendCalc['9x9'][grid],
      ),
      getPoint(
        cardinals.S3.lat,
        cardinals.S3.lng,
        radiusCalc[radius] * 4,
        90,
        6,
        9,
        addendCalc['9x9'][grid],
      ),
      getPoint(
        cardinals.S3.lat,
        cardinals.S3.lng,
        radiusCalc[radius] * 4,
        270,
        6,
        1,
        addendCalc['9x9'][grid],
      ),
      getPoint(
        cardinals.S5.lat,
        cardinals.S5.lng,
        radiusCalc[radius] * 4,
        90,
        7,
        9,
        addendCalc['9x9'][grid],
      ),
      getPoint(
        cardinals.S5.lat,
        cardinals.S5.lng,
        radiusCalc[radius] * 4,
        270,
        7,
        1,
        addendCalc['9x9'][grid],
      ),
      getPoint(
        cardinals.S7.lat,
        cardinals.S7.lng,
        radiusCalc[radius] * 4,
        90,
        8,
        9,
        addendCalc['9x9'][grid],
      ),
      getPoint(
        cardinals.S7.lat,
        cardinals.S7.lng,
        radiusCalc[radius] * 4,
        270,
        8,
        1,
        addendCalc['9x9'][grid],
      ),
      getPoint(
        cardinals.S9.lat,
        cardinals.S9.lng,
        radiusCalc[radius],
        90,
        9,
        6,
        addendCalc['9x9'][grid],
      ),
      getPoint(
        cardinals.S9.lat,
        cardinals.S9.lng,
        radiusCalc[radius],
        270,
        9,
        4,
        addendCalc['9x9'][grid],
      ),
      getPoint(
        cardinals.S9.lat,
        cardinals.S9.lng,
        radiusCalc[radius] * 2,
        90,
        9,
        7,
        addendCalc['9x9'][grid],
      ),
      getPoint(
        cardinals.S9.lat,
        cardinals.S9.lng,
        radiusCalc[radius] * 2,
        270,
        9,
        3,
        addendCalc['9x9'][grid],
      ),
      getPoint(
        cardinals.S9.lat,
        cardinals.S9.lng,
        radiusCalc[radius] * 3,
        90,
        9,
        8,
        addendCalc['9x9'][grid],
      ),
      getPoint(
        cardinals.S9.lat,
        cardinals.S9.lng,
        radiusCalc[radius] * 3,
        270,
        9,
        2,
        addendCalc['9x9'][grid],
      ),
      getPoint(
        cardinals.S9.lat,
        cardinals.S9.lng,
        radiusCalc[radius] * 4,
        90,
        9,
        9,
        addendCalc['9x9'][grid],
      ),
      getPoint(
        cardinals.S9.lat,
        cardinals.S9.lng,
        radiusCalc[radius] * 4,
        270,
        9,
        1,
        addendCalc['9x9'][grid],
      ),
    )
  }
  // 13x13 Grid
  if (gridCalc[grid] > 9) {
    locations.push(
      getPoint(lat, lng, radiusCalc[radius] * 5, 0, 2, 7, 0),
      getPoint(lat, lng, radiusCalc[radius] * 5, 90, 7, 12, 0),
      getPoint(lat, lng, radiusCalc[radius] * 5, 180, 12, 7, 0),
      getPoint(lat, lng, radiusCalc[radius] * 5, 270, 7, 2, 0),
      getPoint(cardinals.N3.lat, cardinals.N3.lng, radiusCalc[radius] * 5, 90, 6, 12, 0),
      getPoint(cardinals.N3.lat, cardinals.N3.lng, radiusCalc[radius] * 5, 270, 6, 2, 0),
      getPoint(cardinals.N5.lat, cardinals.N5.lng, radiusCalc[radius] * 5, 90, 5, 12, 0),
      getPoint(cardinals.N5.lat, cardinals.N5.lng, radiusCalc[radius] * 5, 270, 5, 2, 0),
      getPoint(cardinals.N7.lat, cardinals.N7.lng, radiusCalc[radius] * 5, 90, 4, 12, 0),
      getPoint(cardinals.N7.lat, cardinals.N7.lng, radiusCalc[radius] * 5, 270, 4, 2, 0),
      getPoint(cardinals.N9.lat, cardinals.N9.lng, radiusCalc[radius] * 5, 90, 3, 12, 0),
      getPoint(cardinals.N9.lat, cardinals.N9.lng, radiusCalc[radius] * 5, 270, 3, 2, 0),
      getPoint(cardinals.N11.lat, cardinals.N11.lng, radiusCalc[radius], 90, 2, 8, 0),
      getPoint(cardinals.N11.lat, cardinals.N11.lng, radiusCalc[radius], 270, 2, 6, 0),
      getPoint(cardinals.N11.lat, cardinals.N11.lng, radiusCalc[radius] * 2, 90, 2, 9, 0),
      getPoint(cardinals.N11.lat, cardinals.N11.lng, radiusCalc[radius] * 2, 270, 2, 5, 0),
      getPoint(cardinals.N11.lat, cardinals.N11.lng, radiusCalc[radius] * 3, 90, 2, 10, 0),
      getPoint(cardinals.N11.lat, cardinals.N11.lng, radiusCalc[radius] * 3, 270, 2, 4, 0),
      getPoint(cardinals.N11.lat, cardinals.N11.lng, radiusCalc[radius] * 4, 90, 2, 11, 0),
      getPoint(cardinals.N11.lat, cardinals.N11.lng, radiusCalc[radius] * 4, 270, 2, 3, 0),
      getPoint(cardinals.N11.lat, cardinals.N11.lng, radiusCalc[radius] * 5, 90, 2, 12, 0),
      getPoint(cardinals.N11.lat, cardinals.N11.lng, radiusCalc[radius] * 5, 270, 2, 2, 0),
      getPoint(cardinals.S3.lat, cardinals.S3.lng, radiusCalc[radius] * 5, 90, 8, 12, 0),
      getPoint(cardinals.S3.lat, cardinals.S3.lng, radiusCalc[radius] * 5, 270, 8, 2, 0),
      getPoint(cardinals.S5.lat, cardinals.S5.lng, radiusCalc[radius] * 5, 90, 9, 12, 0),
      getPoint(cardinals.S5.lat, cardinals.S5.lng, radiusCalc[radius] * 5, 270, 9, 2, 0),
      getPoint(cardinals.S7.lat, cardinals.S7.lng, radiusCalc[radius] * 5, 90, 10, 12, 0),
      getPoint(cardinals.S7.lat, cardinals.S7.lng, radiusCalc[radius] * 5, 270, 10, 2, 0),
      getPoint(cardinals.S9.lat, cardinals.S9.lng, radiusCalc[radius] * 5, 90, 11, 12, 0),
      getPoint(cardinals.S9.lat, cardinals.S9.lng, radiusCalc[radius] * 5, 270, 11, 2, 0),
      getPoint(cardinals.S11.lat, cardinals.S11.lng, radiusCalc[radius], 90, 12, 8, 0),
      getPoint(cardinals.S11.lat, cardinals.S11.lng, radiusCalc[radius], 270, 12, 6, 0),
      getPoint(cardinals.S11.lat, cardinals.S11.lng, radiusCalc[radius] * 2, 90, 12, 9, 0),
      getPoint(cardinals.S11.lat, cardinals.S11.lng, radiusCalc[radius] * 2, 270, 12, 5, 0),
      getPoint(cardinals.S11.lat, cardinals.S11.lng, radiusCalc[radius] * 3, 90, 12, 10, 0),
      getPoint(cardinals.S11.lat, cardinals.S11.lng, radiusCalc[radius] * 3, 270, 12, 4, 0),
      getPoint(cardinals.S11.lat, cardinals.S11.lng, radiusCalc[radius] * 4, 90, 12, 11, 0),
      getPoint(cardinals.S11.lat, cardinals.S11.lng, radiusCalc[radius] * 4, 270, 12, 3, 0),
      getPoint(cardinals.S11.lat, cardinals.S11.lng, radiusCalc[radius] * 5, 90, 12, 12, 0),
      getPoint(cardinals.S11.lat, cardinals.S11.lng, radiusCalc[radius] * 5, 270, 12, 2, 0),
      getPoint(lat, lng, radiusCalc[radius] * 6, 0, 1, 7, 0),
      getPoint(lat, lng, radiusCalc[radius] * 6, 90, 7, 13, 0),
      getPoint(lat, lng, radiusCalc[radius] * 6, 180, 13, 7, 0),
      getPoint(lat, lng, radiusCalc[radius] * 6, 270, 7, 1, 0),
      getPoint(cardinals.N3.lat, cardinals.N3.lng, radiusCalc[radius] * 6, 90, 6, 13, 0),
      getPoint(cardinals.N3.lat, cardinals.N3.lng, radiusCalc[radius] * 6, 270, 6, 1, 0),
      getPoint(cardinals.N5.lat, cardinals.N5.lng, radiusCalc[radius] * 6, 90, 5, 13, 0),
      getPoint(cardinals.N5.lat, cardinals.N5.lng, radiusCalc[radius] * 6, 270, 5, 1, 0),
      getPoint(cardinals.N7.lat, cardinals.N7.lng, radiusCalc[radius] * 6, 90, 4, 13, 0),
      getPoint(cardinals.N7.lat, cardinals.N7.lng, radiusCalc[radius] * 6, 270, 4, 1, 0),
      getPoint(cardinals.N9.lat, cardinals.N9.lng, radiusCalc[radius] * 6, 90, 3, 13, 0),
      getPoint(cardinals.N9.lat, cardinals.N9.lng, radiusCalc[radius] * 6, 270, 3, 1, 0),
      getPoint(cardinals.N11.lat, cardinals.N11.lng, radiusCalc[radius] * 6, 90, 2, 13, 0),
      getPoint(cardinals.N11.lat, cardinals.N11.lng, radiusCalc[radius] * 6, 270, 2, 1, 0),
      getPoint(cardinals.N13.lat, cardinals.N13.lng, radiusCalc[radius], 90, 1, 8, 0),
      getPoint(cardinals.N13.lat, cardinals.N13.lng, radiusCalc[radius], 270, 1, 6, 0),
      getPoint(cardinals.N13.lat, cardinals.N13.lng, radiusCalc[radius] * 2, 90, 1, 9, 0),
      getPoint(cardinals.N13.lat, cardinals.N13.lng, radiusCalc[radius] * 2, 270, 1, 5, 0),
      getPoint(cardinals.N13.lat, cardinals.N13.lng, radiusCalc[radius] * 3, 90, 1, 10, 0),
      getPoint(cardinals.N13.lat, cardinals.N13.lng, radiusCalc[radius] * 3, 270, 1, 4, 0),
      getPoint(cardinals.N13.lat, cardinals.N13.lng, radiusCalc[radius] * 4, 90, 1, 11, 0),
      getPoint(cardinals.N13.lat, cardinals.N13.lng, radiusCalc[radius] * 4, 270, 1, 3, 0),
      getPoint(cardinals.N13.lat, cardinals.N13.lng, radiusCalc[radius] * 5, 90, 1, 12, 0),
      getPoint(cardinals.N13.lat, cardinals.N13.lng, radiusCalc[radius] * 5, 270, 1, 2, 0),
      getPoint(cardinals.N13.lat, cardinals.N13.lng, radiusCalc[radius] * 6, 90, 1, 13, 0),
      getPoint(cardinals.N13.lat, cardinals.N13.lng, radiusCalc[radius] * 6, 270, 1, 1, 0),
      getPoint(cardinals.S3.lat, cardinals.S3.lng, radiusCalc[radius] * 6, 90, 8, 13, 0),
      getPoint(cardinals.S3.lat, cardinals.S3.lng, radiusCalc[radius] * 6, 270, 8, 1, 0),
      getPoint(cardinals.S5.lat, cardinals.S5.lng, radiusCalc[radius] * 6, 90, 9, 13, 0),
      getPoint(cardinals.S5.lat, cardinals.S5.lng, radiusCalc[radius] * 6, 270, 9, 1, 0),
      getPoint(cardinals.S7.lat, cardinals.S7.lng, radiusCalc[radius] * 6, 90, 10, 13, 0),
      getPoint(cardinals.S7.lat, cardinals.S7.lng, radiusCalc[radius] * 6, 270, 10, 1, 0),
      getPoint(cardinals.S9.lat, cardinals.S9.lng, radiusCalc[radius] * 6, 90, 11, 13, 0),
      getPoint(cardinals.S9.lat, cardinals.S9.lng, radiusCalc[radius] * 6, 270, 11, 1, 0),
      getPoint(cardinals.S11.lat, cardinals.S11.lng, radiusCalc[radius] * 6, 90, 12, 13, 0),
      getPoint(cardinals.S11.lat, cardinals.S11.lng, radiusCalc[radius] * 6, 270, 12, 1, 0),
      getPoint(cardinals.S13.lat, cardinals.S13.lng, radiusCalc[radius], 90, 13, 8, 0),
      getPoint(cardinals.S13.lat, cardinals.S13.lng, radiusCalc[radius], 270, 13, 6, 0),
      getPoint(cardinals.S13.lat, cardinals.S13.lng, radiusCalc[radius] * 2, 90, 13, 9, 0),
      getPoint(cardinals.S13.lat, cardinals.S13.lng, radiusCalc[radius] * 2, 270, 13, 5, 0),
      getPoint(cardinals.S13.lat, cardinals.S13.lng, radiusCalc[radius] * 3, 90, 13, 10, 0),
      getPoint(cardinals.S13.lat, cardinals.S13.lng, radiusCalc[radius] * 3, 270, 13, 4, 0),
      getPoint(cardinals.S13.lat, cardinals.S13.lng, radiusCalc[radius] * 4, 90, 13, 11, 0),
      getPoint(cardinals.S13.lat, cardinals.S13.lng, radiusCalc[radius] * 4, 270, 13, 3, 0),
      getPoint(cardinals.S13.lat, cardinals.S13.lng, radiusCalc[radius] * 5, 90, 13, 12, 0),
      getPoint(cardinals.S13.lat, cardinals.S13.lng, radiusCalc[radius] * 5, 270, 13, 2, 0),
      getPoint(cardinals.S13.lat, cardinals.S13.lng, radiusCalc[radius] * 6, 90, 13, 13, 0),
      getPoint(cardinals.S13.lat, cardinals.S13.lng, radiusCalc[radius] * 6, 270, 13, 1, 0),
    )
  }
  return locations
}

// File to be used for API integrations
import axios from 'axios'
import cache from './cache'
import { DateTime } from 'luxon'

// Determine request origin
const origin = window.location.origin.includes('localhost')
  ? 'https://omginsite.com'
  : window.location.origin

const API = {
  // Login
  insertUser: (data) => {
    return axios.post(`${origin}/database/request`, {
      method: 'POST',
      url: '/api/uni/insertUser',
      data: { ...data, origin },
    })
  },
  getClient: (data, clientId, cacheData = true) => {
    return new Promise((resolve) => {
      const url = `/api/uni/client/${clientId}`
      const isCacheValid = cache.isValid(url)
      if (isCacheValid) {
        resolve(cache.getCache(url))
      }

      axios
        .post(`${origin}/database/request`, {
          method: 'POST',
          url: url,
          data: { ...data, origin },
        })
        .then((res) => {
          if (res instanceof Error) {
            resolve(res)
          }
          if (res.data.reason !== 'access denied' && cacheData) {
            cache.setCache(url, res.data)
          }
          resolve(res.data)
        })
        .catch((err) => {
          console.error(err, 'axios catch')
          resolve(err)
        })
    })
  },

  // Admin
  getAdminLSAVerticals: () => {
    return new Promise((resolve, reject) => {
      axios
        .post(`${origin}/database/request`, { method: 'GET', url: '/api/vertical/list' })
        .then((res) => {
          resolve(res.data)
        })
        .catch((err) => reject(err))
    })
  },
  createLSAVertical: (data) => {
    return new Promise((resolve, reject) => {
      axios
        .post(`${origin}/database/request`, { method: 'POST', url: '/api/vertical/create', data })
        .then((res) => {
          resolve(res.data)
        })
        .catch((err) => reject(err))
    })
  },
  updateLSAVertical: (data, id) => {
    return new Promise((resolve, reject) => {
      axios
        .post(`${origin}/database/request`, {
          method: 'PUT',
          url: `/api/vertical/update/${id}`,
          data,
        })
        .then((res) => {
          resolve(res.data)
        })
        .catch((err) => reject(err))
    })
  },

  // Employee
  getUserClients: (data) => {
    return new Promise((resolve, reject) => {
      axios
        .post(`${origin}/database/request`, {
          method: 'POST',
          url: '/api/uni/user/clients',
          data: { ...data, origin },
        })
        .then((res) => {
          resolve(res.data)
        })
        .catch((err) => reject(err))
    })
  },
  getSalesTopRankings: () => {
    return new Promise((resolve, reject) => {
      axios
        .post(`${origin}/database/request`, { method: 'GET', url: '/api/main/toprankings' })
        .then((res) => {
          resolve(res.data)
        })
        .catch((err) => reject(err))
    })
  },
  getLatestSalesTopRankings: () => {
    return new Promise((resolve, reject) => {
      axios
        .post(`${origin}/database/request`, { method: 'GET', url: '/api/main/latest/toprankings' })
        .then((res) => {
          resolve(res.data)
        })
        .catch((err) => reject(err))
    })
  },
  getTimelineSearch: (data) => {
    return new Promise((resolve, reject) => {
      axios
        .post(`${origin}/database/request`, {
          method: 'POST',
          url: '/api/ranktimeline/find/search',
          data,
        })
        .then((res) => {
          const sorted = res.data.sort((a, b) => {
            return DateTime.fromISO(a.date) - DateTime.fromISO(b.date)
          })
          let timelines = {}
          for (let i = 0; i < sorted.length; i++) {
            if (!timelines[sorted[i].item_id]) {
              timelines[sorted[i].item_id] = []
            }
            if (
              timelines[sorted[i].item_id].findIndex(
                (x) =>
                  x.config.keyword === sorted[i].keyword &&
                  x.config.size === sorted[i].size &&
                  x.config.radius === sorted[i].radius,
              ) < 0
            ) {
              timelines[sorted[i].item_id].push({
                config: {
                  keyword: sorted[i].keyword,
                  size: sorted[i].size,
                  radius: sorted[i].radius,
                },
                data: [],
              })
            }
            const index = timelines[sorted[i].item_id].findIndex(
              (x) =>
                x.config.keyword === sorted[i].keyword &&
                x.config.size === sorted[i].size &&
                x.config.radius === sorted[i].radius,
            )
            timelines[sorted[i].item_id][index].data.push({
              average_total_grid_rank: sorted[i].average_total_grid_rank,
              company_name: sorted[i].company_name,
              date: DateTime.fromISO(sorted[i].date).toFormat('yyyy-MM-dd'),
              display: sorted[i].display,
              location: sorted[i].location,
            })
          }

          resolve(timelines)
        })
        .catch((err) => reject(err))
    })
  },
  getVerticalList: () => {
    return new Promise((resolve, reject) => {
      axios
        .post(`${origin}/database/request`, { method: 'GET', url: '/api/vertical/list' })
        .then((res) => {
          const list = res.data.map((item) => item.vertical).sort()
          resolve(list)
        })
        .catch((err) => reject(err))
    })
  },
  getVerticalInfo: (data) => {
    return new Promise((resolve, reject) => {
      axios
        .post(`${origin}/database/request`, { method: 'POST', url: '/api/vertical/info', data })
        .then((res) => {
          resolve(res.data)
        })
        .catch((err) => reject(err))
    })
  },
  getGooglePlacesUS: () => {
    return new Promise((resolve, reject) => {
      axios
        .post(`${origin}/database/request`, { method: 'GET', url: '/api/googleads/geotargets/us' })
        .then((res) => {
          const places = Object.values(res.data).filter(
            (x) =>
              x.place_type === 'Country' ||
              x.place_type === 'State' ||
              x.place_type === 'County' ||
              x.place_type === 'City',
          )
          const searchList = places.map((place) => ({
            id: place.place_id,
            name: place.full_name.split(',').join(', '),
          }))
          resolve(searchList)
        })
        .catch((err) => reject(err))
    })
  },
  getKeywordPlannerCalculation: (data) => {
    return new Promise((resolve, reject) => {
      axios
        .post(`${origin}/database/request`, {
          method: 'POST',
          url: '/api/googleads/estimator/calculate',
          data,
        })
        .then((res) => {
          resolve(res.data)
        })
        .catch((err) => reject(err))
    })
  },

  // OMG Custom Rank Grid
  sendCustomBatch: (data) => {
    return new Promise((resolve, reject) => {
      axios
        .post(`${origin}/database/request`, { method: 'POST', url: '/api/sales/batch', data })
        .then((res) => {
          resolve(res.data)
        })
        .catch((err) => reject(err))
    })
  },
  getOMGEmployee: (identifier) => {
    return new Promise((resolve, reject) => {
      axios
        .post(`${origin}/database/request`, {
          method: 'GET',
          url: `/api/omgemployee/find/${identifier}`,
        })
        .then((res) => {
          resolve(res.data)
        })
        .catch((err) => reject(err))
    })
  },
  removeSalesAlert: (identifier, ref_id) => {
    return new Promise((resolve, reject) => {
      axios
        .post(`${origin}/database/request`, {
          method: 'DELETE',
          url: `/api/omgemployee/remove/grid/${identifier}/${ref_id}`,
        })
        .then((res) => {
          resolve(res.data)
        })
        .catch((err) => reject(err))
    })
  },
  getEmployeeHistory: (identifier) => {
    return new Promise((resolve, reject) => {
      axios
        .post(`${origin}/database/request`, {
          method: 'GET',
          url: `/api/sales/history/${identifier}`,
        })
        .then((res) => {
          resolve(res.data)
        })
        .catch((err) => reject(err))
    })
  },
  getProspectData: (id) => {
    return new Promise((resolve, reject) => {
      axios
        .post(`${origin}/database/request`, { method: 'GET', url: `/api/sales/data/${id}` })
        .then((res) => {
          resolve(res.data)
        })
        .catch((err) => reject(err))
    })
  },
  getCustomGrid: (id) => {
    return new Promise((resolve, reject) => {
      axios
        .post(`${origin}/database/request`, { method: 'GET', url: `/api/sales/find/${id}` })
        .then((res) => {
          resolve(res.data)
        })
        .catch((err) => reject(err))
    })
  },
  getPlaceDetails: (place_id) => {
    return new Promise((resolve, reject) => {
      axios
        .post(`${origin}/database/request`, {
          method: 'POST',
          url: '/api/sales/places/details',
          data: { place_id },
        })
        .then((res) => {
          resolve(res.data)
        })
        .catch((err) => reject(err))
    })
  },

  // Activity
  getActivity: (data, clientId) => {
    return new Promise((resolve, reject) => {
      switch (cache.isValid(`${origin}/api/uni/client/activity/${clientId}`)) {
        case true:
          resolve(cache.getCache(`${origin}/api/uni/client/activity/${clientId}`))
          break
        default:
          axios
            .post(`${origin}/database/request`, {
              method: 'POST',
              url: `/api/uni/client/activity/${clientId}`,
              data: { ...data, origin },
            })
            .then((res) => {
              if (res.data.reason !== 'access denied') {
                cache.setCache(`${origin}/api/uni/client/activity/${clientId}`, res.data)
              }
              resolve(res.data)
            })
            .catch((err) => reject(err))
      }
    })
  },
  getNotifications: (data, clientId) => {
    return new Promise((resolve, reject) => {
      axios
        .post(`${origin}/database/request`, {
          method: 'POST',
          url: `/api/uni/client/notifications/${clientId}`,
          data: { ...data, origin },
        })
        .then((res) => resolve(res.data))
        .catch((err) => reject(err))
    })
  },
  getBlogData: (feed) => {
    return axios.post(feed)
  },

  // Performance Report
  getFacebookAdsPerformance: (data, clientId, query) => {
    return new Promise((resolve, reject) => {
      console.log('calling...')
      axios
        .post(`https://dev.omginsite.com/database/request`, {
          method: 'POST',
          url: `/api/uni/charts/performancereport/fbads/${clientId}${query}`,
          data: { ...data, origin },
        })
        .then((res) => {
          console.log(res, 'fbads')
          if (res.data.reason !== 'access denied') {
            cache.setCache(
              `${origin}/api/uni/charts/performancereport/fbads/${clientId}${query}`,
              res.data,
            )
          }
          resolve(res.data)
        })
        .catch((err) => reject(err))
      // }
    })
  },
  getGoogleProfilePerformance: (data, clientId, query) => {
    return new Promise((resolve, reject) => {
      switch (
        cache.isValid(`${origin}/api/uni/charts/performancereport/google/${clientId}${query}`)
      ) {
        case true:
          resolve(
            cache.getCache(`${origin}/api/uni/charts/performancereport/google/${clientId}${query}`),
          )
          break
        default:
          axios
            .post(`${origin}/database/request`, {
              method: 'POST',
              url: `/api/uni/charts/performancereport/google/${clientId}${query}`,
              data: { ...data, origin },
            })
            .then((res) => {
              if (res.data.reason !== 'access denied') {
                cache.setCache(
                  `${origin}/api/uni/charts/performancereport/google/${clientId}${query}`,
                  res.data,
                )
              }
              resolve(res.data)
            })
            .catch((err) => reject(err))
      }
    })
  },
  getListingsPerformance: (data, clientId, query) => {
    return new Promise((resolve, reject) => {
      switch (
        cache.isValid(`${origin}/api/uni/charts/performancereport/listings/${clientId}${query}`)
      ) {
        case true:
          resolve(
            cache.getCache(
              `${origin}/api/uni/charts/performancereport/listings/${clientId}${query}`,
            ),
          )
          break
        default:
          axios
            .post(`${origin}/database/request`, {
              method: 'POST',
              url: `/api/uni/charts/performancereport/listings/${clientId}${query}`,
              data: { ...data, origin },
            })
            .then((res) => {
              if (res.data.reason !== 'access denied') {
                cache.setCache(
                  `${origin}/api/uni/charts/performancereport/listings/${clientId}${query}`,
                  res.data,
                )
              }
              resolve(res.data)
            })
            .catch((err) => reject(err))
      }
    })
  },
  getFacebookOrganic: (data, clientId, query) => {
    return new Promise((resolve, reject) => {
      switch (
        cache.isValid(`${origin}/api/uni/charts/performancereport/facebook/${clientId}${query}`)
      ) {
        case true:
          resolve(
            cache.getCache(
              `${origin}/api/uni/charts/performancereport/facebook/${clientId}${query}`,
            ),
          )
          break
        default:
          axios
            .post(`${origin}/database/request`, {
              method: 'POST',
              url: `/api/uni/charts/performancereport/facebook/${clientId}${query}`,
              data: { ...data, origin },
            })
            .then((res) => {
              if (res.data.reason !== 'access denied') {
                cache.setCache(
                  `${origin}/api/uni/charts/performancereport/facebook/${clientId}${query}`,
                  res.data,
                )
              }
              resolve(res.data)
            })
            .catch((err) => reject(err))
      }
    })
  },
  getReviewManagement: (data, clientId) => {
    return new Promise((resolve, reject) => {
      switch (cache.isValid(`${origin}/api/uni/charts/performancereport/reviews/${clientId}`)) {
        case true:
          resolve(cache.getCache(`${origin}/api/uni/charts/performancereport/reviews/${clientId}`))
          break
        default:
          axios
            .post(`${origin}/database/request`, {
              method: 'POST',
              url: `/api/uni/products/reviews/${clientId}`,
              data: { ...data, origin },
            })
            .then((res) => {
              if (res.data.reason !== 'access denied') {
                cache.setCache(
                  `${origin}/api/uni/charts/performancereport/reviews/${clientId}`,
                  res.data,
                )
              }
              resolve(res.data)
            })
            .catch((err) => reject(err))
      }
    })
  },
  getAdsPerformanceReport: (data, timeframe, item_id) => {
    return new Promise((resolve, reject) => {
      switch (
        cache.isValid(
          `${origin}/api/googleads/performance/report/${data.customer_id}?from_date=${timeframe.from_date}&to_date=${timeframe.to_date}`,
        )
      ) {
        case true:
          resolve(
            cache.getCacheV2(
              `${origin}/api/googleads/performance/report/${data.customer_id}?from_date=${timeframe.from_date}&to_date=${timeframe.to_date}`,
            ),
          )
          break
        default:
          axios
            .post(`${origin}/database/request`, {
              method: 'POST',
              url: '/api/googleads/performance/report',
              data: { ...data, origin },
            })
            .then((res) => {
              res.data.item_id = item_id
              res.data.product = 'Google Ads'
              if (res.data.reason !== 'access denied') {
                cache.setCacheV2(
                  `${origin}/api/googleads/performance/report/${data.customer_id}?from_date=${timeframe.from_date}&to_date=${timeframe.to_date}`,
                  res.data,
                )
              }
              resolve(res.data)
            })
            .catch((err) => reject(err))
      }
    })
  },
  getLSAPerformanceReport: (data, timeframe, item_id) => {
    return new Promise((resolve, reject) => {
      switch (
        cache.isValid(
          `${origin}/api/lsa/performance/report/${data.customer_id}?from_date=${timeframe.from_date}&to_date=${timeframe.to_date}`,
        )
      ) {
        case true:
          resolve(
            cache.getCacheV2(
              `${origin}/api/lsa/performance/report/${data.customer_id}?from_date=${timeframe.from_date}&to_date=${timeframe.to_date}`,
            ),
          )
          break
        default:
          axios
            .post(`${origin}/database/request`, {
              method: 'POST',
              url: '/api/lsa/performance/report',
              data,
            })
            .then((res) => {
              res.data.item_id = item_id
              res.data.product = 'Local Services Ads'
              if (res.data.reason !== 'access denied') {
                cache.setCacheV2(
                  `${origin}/api/lsa/performance/report/${data.customer_id}?from_date=${timeframe.from_date}&to_date=${timeframe.to_date}`,
                  res.data,
                )
              }
              resolve(res.data)
            })
            .catch((err) => reject(err))
      }
    })
  },
  getWebAnalytics: (data, clientId, query) => {
    return new Promise((resolve, reject) => {
      switch (cache.isValid(`${origin}/api/uni/charts/performancereport/web/${clientId}${query}`)) {
        case true:
          console.log(
            cache.getCache(`${origin}/api/uni/charts/performancereport/web/${clientId}${query}`),
          )
          resolve(
            cache.getCache(`${origin}/api/uni/charts/performancereport/web/${clientId}${query}`),
          )
          break
        default:
          axios
            .post(`${origin}/database/request`, {
              method: 'POST',
              url: `/api/uni/charts/performancereport/web/${clientId}${query}`,
              data: { ...data, origin },
            })
            .then((res) => {
              if (res.data.reason !== 'access denied') {
                for (const [key, value] of Object.entries(res.data)) {
                  if (value.error) {
                    delete res.data[key]
                  }
                }
                cache.setCache(
                  `${origin}/api/uni/charts/performancereport/web/${clientId}${query}`,
                  res.data,
                )
              }
              console.log(res.data, 'web')
              resolve(res.data)
            })
            .catch((err) => reject(err))
      }
    })
  },

  // OMG Local Rank Grid
  getKeywords: (data, clientId) => {
    return new Promise((resolve, reject) => {
      const cacheKey = `${origin}/api/uni/products/rankgrid/${clientId}`
      if (cache.isValid(cacheKey)) {
        resolve(cache.getCache(cacheKey))
      } else {
        axios
          .post(`${origin}/database/request`, {
            method: 'POST',
            url: `/api/uni/products/rankgrid/${clientId}`,
            data: { ...data, origin },
          })
          .then((res) => {
            if (res.data.reason !== 'access denied') {
              cache.setCache(cacheKey, res.data)
            }
            resolve(res.data)
          })
          .catch((err) => reject(err))
      }
    })
  },
  getMonthySearches: () => {
    return new Promise((resolve, reject) => {
      const cacheKey = `${origin}/api/uni/system/rankgrid`
      if (cache.isValid(cacheKey)) {
        resolve(cache.getCache(cacheKey))
      } else {
        axios
          .post(`${origin}/database/request`, {
            method: 'POST',
            url: '/api/uni/system/rankgrid',
            data: { origin },
          })
          .then((res) => {
            if (res.data.reason !== 'access denied') {
              cache.setCache(cacheKey, res.data)
            }
            resolve(res.data)
          })
          .catch((err) => reject(err))
      }
    })
  },
  sendLiveBatch: (data) => {
    return new Promise((resolve, reject) => {
      axios
        .post(`${origin}/database/request`, { method: 'POST', url: '/api/liverank/batch', data })
        .then((res) => resolve(res.data))
        .catch((err) => reject(err))
    })
  },
  getRankClient: (id) => {
    return new Promise((resolve, reject) => {
      axios
        .post(`${origin}/database/request`, { method: 'GET', url: `/api/rankclient/find/${id}` })
        .then((res) => resolve(res.data))
        .catch((err) => reject(err))
    })
  },
  activateGridAccount: (data) => {
    return new Promise((resolve, reject) => {
      axios
        .post(`${origin}/database/request`, { method: 'POST', url: '/api/rankclient/create', data })
        .then((res) => resolve(res.data))
        .catch((err) => reject(err))
    })
  },
  getClientHistory: (client_id) => {
    return new Promise((resolve, reject) => {
      axios
        .post(`${origin}/database/request`, {
          method: 'GET',
          url: `/api/grids/history/${client_id}`,
        })
        .then((res) => resolve(res.data))
        .catch((err) => reject(err))
    })
  },
  getRankData: (id) => {
    return new Promise((resolve, reject) => {
      axios
        .post(`${origin}/database/request`, { method: 'GET', url: `/api/grids/data/${id}` })
        .then((res) => resolve(res.data))
        .catch((err) => {
          reject(err)
        })
    })
  },
  getRankGrid: (id) => {
    return new Promise((resolve, reject) => {
      axios
        .post(`${origin}/database/request`, { method: 'GET', url: `/api/grids/find/${id}` })
        .then((res) => resolve(res.data))
        .catch((err) => reject(err))
    })
  },
  getClientTimelines: (client_id) => {
    return new Promise((resolve, reject) => {
      axios
        .post(`${origin}/database/request`, {
          method: 'GET',
          url: `/api/ranktimeline/find/client/${client_id}`,
        })
        .then((res) => {
          const sorted = res.data.sort((a, b) => {
            return DateTime.fromISO(a.date) - DateTime.fromISO(b.date)
          })
          let timelines = {}
          for (let i = 0; i < sorted.length; i++) {
            if (!timelines[sorted[i].item_id]) {
              timelines[sorted[i].item_id] = []
            }
            if (
              timelines[sorted[i].item_id].findIndex(
                (x) =>
                  x.config.keyword === sorted[i].keyword &&
                  x.config.size === sorted[i].size &&
                  x.config.radius === sorted[i].radius,
              ) < 0
            ) {
              timelines[sorted[i].item_id].push({
                config: {
                  keyword: sorted[i].keyword,
                  size: sorted[i].size,
                  radius: sorted[i].radius,
                },
                data: [],
              })
            }
            const index = timelines[sorted[i].item_id].findIndex(
              (x) =>
                x.config.keyword === sorted[i].keyword &&
                x.config.size === sorted[i].size &&
                x.config.radius === sorted[i].radius,
            )
            timelines[sorted[i].item_id][index].data.push({
              average_total_grid_rank: sorted[i].average_total_grid_rank,
              company_name: sorted[i].company_name,
              date: DateTime.fromISO(sorted[i].date).toFormat('yyyy-MM-dd'),
              display: sorted[i].display,
              location: sorted[i].location,
            })
          }

          resolve(timelines)
        })
        .catch((err) => reject(err))
    })
  },
  removeRankAlert: (client_id, ref_id) => {
    return new Promise((resolve, reject) => {
      axios
        .post(`${origin}/database/request`, {
          method: 'DELETE',
          url: `/api/rankclient/remove/alert/${client_id}/${ref_id}`,
        })
        .then((res) => resolve(res.data))
        .catch((err) => reject(err))
    })
  },

  // Google Business Pro
  getGBPOverview: (data, clientId, itemId) => {
    return new Promise((resolve, reject) => {
      const cacheKey = `${origin}/api/uni/products/gbp/${clientId}/${itemId}`
      if (cache.isValid(cacheKey)) {
        resolve(cache.getCache(cacheKey))
      } else {
        axios
          .post(`${origin}/database/request`, {
            method: 'POST',
            url: `/api/uni/products/gbp/${clientId}/${itemId}`,
            data: { ...data, origin },
          })
          .then((res) => {
            if (res.data.reason !== 'access denied') {
              cache.setCache(cacheKey, res.data)
            }
            resolve(res.data)
          })
          .catch((err) => reject(err))
      }
    })
  },

  // Local Marketing Essentials
  getLMEOverview: (data, clientId, itemId) => {
    const url = `${origin}/api/uni/products/lme/${clientId}/${itemId}`

    return new Promise((resolve, reject) => {
      switch (cache.isValid(url)) {
        case true:
          resolve(cache.getCache(url))
          break
        default:
          axios
            .post(`${origin}/database/request`, {
              method: 'POST',
              url: `/api/uni/products/lme/${clientId}/${itemId}`,
              data: { ...data, origin },
            })
            .then((res) => {
              if (res.data.reason !== 'access denied') {
                cache.setCache(url, res.data)
              }
              resolve(res.data)
            })
            .catch((err) => {
              reject(err)
            })
      }
    })
  },

  // Managed Business Listings
  getMBLOverview: (data, clientId, itemId) => {
    const url = `${origin}/api/uni/products/mbl/${clientId}/${itemId}`

    return new Promise((resolve, reject) => {
      switch (cache.isValid(url)) {
        case true:
          resolve(cache.getCache(url))
          break
        default:
          axios
            .post(`${origin}/database/request`, {
              method: 'POST',
              url: `/api/uni/products/mbl/${clientId}/${itemId}`,
              data: { ...data, origin },
            })
            .then((res) => {
              if (res.data.reason !== 'access denied') {
                cache.setCache(url, res.data)
              }
              resolve(res.data)
            })
            .catch((err) => {
              reject(err)
            })
      }
    })
  },

  // Facebook Pro
  getFBPOverview: (data, clientId, itemId) => {
    const url = `${origin}/api/uni/products/fbp/${clientId}/${itemId}`

    return new Promise((resolve, reject) => {
      switch (cache.isValid(url)) {
        case true:
          resolve(cache.getCache(url))
          break
        default:
          axios
            .post(`${origin}/database/request`, {
              method: 'POST',
              url: `/api/uni/products/fbp/${clientId}/${itemId}`,
              data: { ...data, origin },
            })
            .then((res) => {
              if (res.data.reason !== 'access denied') {
                cache.setCache(url, res.data)
              }
              resolve(res.data)
            })
            .catch((err) => {
              reject(err)
            })
      }
    })
  },

  // Google Ads
  getGoogleAdsOverview: (data, clientId, itemId) => {
    const url = `${origin}/api/uni/products/googleads/${clientId}/${itemId}`

    return new Promise((resolve, reject) => {
      switch (cache.isValid(url)) {
        case true:
          resolve(cache.getCache(url))
          break
        default:
          axios
            .post(`${origin}/database/request`, {
              method: 'POST',
              url: `/api/uni/products/googleads/${clientId}/${itemId}`,
              data: { ...data, origin },
            })
            .then((res) => {
              res.data.item_id = itemId
              if (res.data.reason !== 'access denied') {
                cache.setCache(url, res.data)
              }
              resolve(res.data)
            })
            .catch((err) => {
              reject(err)
            })
      }
    })
  },
  getAccountMetrics: (data, timeframe) => {
    const url = `${origin}/api/googleads/account/metrics/${data.customer_id}/${timeframe}`

    return new Promise((resolve, reject) => {
      switch (cache.isValid(url)) {
        case true:
          resolve(cache.getCacheV2(url))
          break
        default:
          axios
            .post(`${origin}/database/request`, {
              method: 'POST',
              url: `/api/googleads/account/metrics`,
              data: { ...data, origin },
            })
            .then((res) => {
              if (res.data.reason !== 'access denied') {
                cache.setCacheV2(url, res.data)
              }
              resolve(res.data)
            })
            .catch((err) => {
              reject(err)
            })
      }
    })
  },
  getAdsSnapshot: (data, timeframe) => {
    const url = `${origin}/api/googleads/account/snapshot/${data.customer_id}/${timeframe}/${data.from_date}/${data.to_date}`

    return new Promise((resolve, reject) => {
      switch (cache.isValid(url)) {
        case true:
          resolve(cache.getCacheV2(url))
          break
        default:
          axios
            .post(`${origin}/database/request`, {
              method: 'POST',
              url: `/api/googleads/account/snapshot`,
              data: { ...data, origin },
            })
            .then((res) => {
              if (res.data.reason !== 'access denied') {
                cache.setCacheV2(url, res.data)
              }
              resolve(res.data)
            })
            .catch((err) => {
              reject(err)
            })
      }
    }).catch((err) => {
      return err
    })
  },
  getCampaignMetrics: (data, timeframe) => {
    const url = `${origin}/api/googleads/campaigns/metrics/${data.customer_id}/${timeframe}`

    return new Promise((resolve, reject) => {
      switch (cache.isValid(url)) {
        case true:
          resolve(cache.getCacheV2(url))
          break
        default:
          axios
            .post(`${origin}/database/request`, {
              method: 'POST',
              url: `/api/googleads/campaigns/metrics`,
              data: { ...data, origin },
            })
            .then((res) => {
              if (res.data.reason !== 'access denied') {
                cache.setCacheV2(url, res.data)
              }
              resolve(res.data)
            })
            .catch((err) => {
              reject(err)
            })
      }
    })
  },
  getAdsLocationsReport: (data) => {
    const url = `${origin}/api/googleads/reports/locations/${data.customer_id}`

    return new Promise((resolve, reject) => {
      switch (cache.isValid(url)) {
        case true:
          resolve(cache.getCacheV2(url))
          break
        default:
          axios
            .post(`${origin}/database/request`, {
              method: 'POST',
              url: `/api/googleads/reports/locations`,
              data: data,
            })
            .then((res) => {
              if (res.data.reason !== 'access denied') {
                cache.setCacheV2(url, res.data)
              }
              resolve(res.data)
            })
            .catch((err) => {
              reject(err)
            })
      }
    })
  },
  getAdsSchedules: (data) => {
    const url = `${origin}/api/googleads/campaigns/schedules/${data.customer_id}`

    return new Promise((resolve, reject) => {
      switch (cache.isValid(url)) {
        case true:
          resolve(cache.getCacheV2(url))
          break
        default:
          axios
            .post(`${origin}/database/request`, {
              method: 'POST',
              url: `/api/googleads/campaigns/schedules`,
              data: data,
            })
            .then((res) => {
              if (res.data.reason !== 'access denied') {
                cache.setCacheV2(url, res.data)
              }
              resolve(res.data)
            })
            .catch((err) => {
              reject(err)
            })
      }
    })
  },
  getAdsKeywordsReport: (data, timeframe) => {
    const url = `${origin}/api/googleads/reports/keywords/${data.customer_id}/${timeframe}`
    return new Promise((resolve, reject) => {
      switch (cache.isValid(url)) {
        case true:
          resolve(cache.getCacheV2(url))
          break
        default:
          axios
            .post(`${origin}/database/request`, {
              method: 'POST',
              url: `/api/googleads/reports/keywords`,
              data: data,
            })
            .then((res) => {
              if (res.data.reason !== 'access denied') {
                cache.setCacheV2(url, res.data)
              }
              resolve(res.data)
            })
            .catch((err) => {
              reject(err)
            })
      }
    })
  },
  getAdsInfo: (data, timeframe) => {
    const url = `${origin}/api/googleads/info/ads/${data.customer_id}/${timeframe}`

    return new Promise((resolve, reject) => {
      switch (cache.isValid(url)) {
        case true:
          resolve(cache.getCacheV2(url))
          break
        default:
          axios
            .post(`${origin}/database/request`, {
              method: 'POST',
              url: `/api/googleads/info/ads`,
              data: data,
            })
            .then((res) => {
              if (res.data.reason !== 'access denied') {
                cache.setCacheV2(url, res.data)
              }
              resolve(res.data)
            })
            .catch((err) => {
              reject(err)
            })
      }
    })
  },
  getAllAds: (data, timeframe) => {
    const url = `${origin}/api/googleads/ads/all/${data.customer_id}/${timeframe}`

    return new Promise((resolve, reject) => {
      switch (cache.isValid(url)) {
        case true:
          resolve(cache.getCacheV2(url))
          break
        default:
          axios
            .post(`${origin}/database/request`, {
              method: 'POST',
              url: `/api/googleads/ads/all`,
              data: data,
            })
            .then((res) => {
              if (res.data.reason !== 'access denied') {
                cache.setCacheV2(url, res.data)
              }
              resolve(res.data)
            })
            .catch((err) => {
              reject(err)
            })
      }
    })
  },
  getImageAssets: (data, type) => {
    return new Promise((resolve) => {
      switch (
        cache.isValid(
          `https://dev.omginsite.com/api/googleads/assets/images/${data.customer_id}/${type}`,
        )
      ) {
        case true:
          resolve(
            cache.getCacheV2(
              `https://dev.omginsite.com/api/googleads/assets/images/${data.customer_id}/${type}`,
            ),
          )
          break
        default:
          axios
            .post(`${origin}/database/request`, {
              method: 'POST',
              url: 'https://dev.omginsite.com/api/googleads/assets/images',
              data: data,
            })
            .then((res) => {
              if (res.data.reason !== 'access denied') {
                cache.setCacheV2(
                  `https://dev.omginsite.com/api/googleads/assets/images/${data.customer_id}/${type}`,
                  res.data,
                )
              }
              resolve(res.data)
            })
      }
    })
  },
  getGeoTargetConstants: () => {
    return new Promise((resolve, reject) => {
      axios
        .post(`${origin}/database/request`, {
          method: 'GET',
          url: `/api/googleads/geotargets`,
        })
        .then((res) => {
          resolve(res.data)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  getGeoTargetsUS: () => {
    return new Promise((resolve, reject) => {
      axios
        .post(`${origin}/database/request`, {
          method: 'GET',
          url: `/api/googleads/geotargets/us`,
        })
        .then((res) => {
          resolve(res.data)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  getGeoTargetsByCodes: (codes) => {
    return new Promise((resolve, reject) => {
      const promises = []
      codes.forEach((code) => {
        promises.push(
          axios.post(`${origin}/database/request`, {
            method: 'GET',
            url: `/api/googleads/geotargets/${code}`,
          }),
        )
      })
      Promise.all(promises)
        .then((res) => {
          let geoTargets = {}
          res.forEach((item) => {
            geoTargets = { ...geoTargets, ...item.data }
          })
          resolve(geoTargets)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },

  // Ads Xpress
  getAdsXpressOverview: (data, clientId, itemId) => {
    return new Promise((resolve, reject) => {
      switch (cache.isValid(`${origin}/api/uni/products/adsxpress/${clientId}/${itemId}`)) {
        case true:
          resolve(cache.getCache(`${origin}/api/uni/products/adsxpress/${clientId}/${itemId}`))
          break
        default:
          axios
            .post(`${origin}/database/request`, {
              method: 'POST',
              url: `/api/uni/products/adsxpress/${clientId}/${itemId}`,
              data: {
                ...data,
                origin,
              },
            })
            .then((res) => {
              res.data.item_id = itemId
              if (res.data.reason !== 'access denied') {
                cache.setCache(
                  `${origin}/api/uni/products/adsxpress/${clientId}/${itemId}`,
                  res.data,
                )
              }

              resolve(res.data)
            })
            .catch((err) => {
              reject(err)
            })
      }
    })
  },

  // Facebook Ads
  getFbAdsOverview: (data, clientId, itemId) => {
    console.log(`${origin}/api/uni/products/fbads/${clientId}/${itemId}`)
    return new Promise((resolve, reject) => {
      switch (cache.isValid(`${origin}/api/uni/products/fbads/${clientId}/${itemId}`)) {
        case true:
          resolve(cache.getCache(`${origin}/api/uni/products/fbads/${clientId}/${itemId}`))
          break
        default:
          axios
            .post(`${origin}/database/request`, {
              method: 'POST',
              url: `/api/uni/products/fbads/${clientId}/${itemId}`,
              data: { ...data, origin },
            })
            .then((res) => {
              if (res.data.reason !== 'access denied') {
                cache.setCache(`${origin}/api/uni/products/fbads/${clientId}/${itemId}`, res.data)
              }
              resolve(res.data)
            })
            .catch((err) => {
              reject(err)
            })
      }
    })
  },

  // Google Calls Advisor
  getGCAOverview: (data, clientId, itemId) => {
    return new Promise((resolve, reject) => {
      switch (cache.isValid(`${origin}/api/uni/products/gca/${clientId}/${itemId}`)) {
        case true:
          resolve(cache.getCache(`${origin}/api/uni/products/gca/${clientId}/${itemId}`))
          break
        default:
          axios
            .post(`${origin}/database/request`, {
              method: 'POST',
              url: `/api/uni/products/gca/${clientId}/${itemId}`,
              data: { ...data, origin },
            })
            .then((res) => {
              res.data.item_id = itemId
              if (res.data.reason !== 'access denied') {
                cache.setCache(`${origin}/api/uni/products/gca/${clientId}/${itemId}`, res.data)
              }
              resolve(res.data)
            })
            .catch((err) => {
              reject(err)
            })
      }
    })
  },
  getGCAVerticals: (data, clientId, itemId) => {
    return new Promise((resolve, reject) => {
      switch (cache.isValid(`${origin}/api/uni/products/gca/${clientId}/${itemId}`)) {
        case true:
          resolve(cache.getCache(`${origin}/api/uni/products/gca/${clientId}/${itemId}`))
          break
        default:
          axios
            .post(`${origin}/database/request`, {
              method: 'POST',
              url: `/api/uni/products/gca/${clientId}/${itemId}`,
              data: { ...data, origin },
            })
            .then((res) => {
              if (res.data.reason !== 'access denied') {
                cache.setCache(`${origin}/api/uni/products/gca/${clientId}/${itemId}`, res.data)
              }

              resolve(res.data)
            })
            .catch((err) => {
              reject(err)
            })
      }
    })
  },

  // Local Services Ads
  getLSAVerticals: (data, clientId, itemId) => {
    return new Promise((resolve, reject) => {
      switch (cache.isValid(`${origin}/api/uni/products/lsa/${clientId}/${itemId}`)) {
        case true:
          resolve(cache.getCache(`${origin}/api/uni/products/lsa/${clientId}/${itemId}`))
          break
        default:
          axios
            .post(`${origin}/database/request`, {
              method: 'POST',
              url: `/api/uni/products/lsa/${clientId}/${itemId}`,
              data: { ...data, origin },
            })
            .then((res) => {
              res.data.item_id = itemId
              if (res.data.reason !== 'access denied') {
                cache.setCache(`${origin}/api/uni/products/lsa/${clientId}/${itemId}`, res.data)
              }
              resolve(res.data)
            })
            .catch((err) => {
              reject(err)
            })
      }
    })
  },
  getLSAMetrics: (data, timeframe) => {
    return new Promise((resolve, reject) => {
      switch (cache.isValid(`${origin}/api/lsa/account/metrics/${data.customer_id}/${timeframe}`)) {
        case true:
          resolve(
            cache.getCacheV2(`${origin}/api/lsa/account/metrics/${data.customer_id}/${timeframe}`),
          )
          break
        default:
          axios
            .post(`${origin}/database/request`, {
              method: 'POST',
              url: `/api/lsa/account/metrics`,
              data: data,
            })
            .then((res) => {
              if (res.data.reason !== 'access denied') {
                cache.setCacheV2(
                  `${origin}/api/lsa/account/metrics/${data.customer_id}/${timeframe}`,
                  res.data,
                )
              }
              resolve(res.data)
            })
            .catch((err) => {
              reject(err)
            })
      }
    })
  },
  getLSAPerformance: (data, timeframe) => {
    return new Promise((resolve, reject) => {
      switch (
        cache.isValid(
          `${origin}/api/lsa/account/performance/${data.customer_id}/${timeframe}/${data.from_date}/${data.to_date}`,
        )
      ) {
        case true:
          resolve(
            cache.getCacheV2(
              `${origin}/api/lsa/account/performance/${data.customer_id}/${timeframe}/${data.from_date}/${data.to_date}`,
            ),
          )
          break
        default:
          axios
            .post(`${origin}/database/request`, {
              method: 'POST',
              url: `/api/lsa/account/performance`,
              data: data,
            })
            .then((res) => {
              if (res.data.reason !== 'access denied') {
                cache.setCacheV2(
                  `${origin}/api/lsa/account/performance/${data.customer_id}/${timeframe}/${data.from_date}/${data.to_date}`,
                  res.data,
                )
              }
              resolve(res.data)
            })
            .catch((err) => {
              reject(err)
            })
      }
    })
  },
  getLSADisputesAndCredits: (data) => {
    return new Promise((resolve, reject) => {
      switch (cache.isValid(`${origin}/api/lsa/account/disputes/${data.customer_id}`)) {
        case true:
          resolve(cache.getCacheV2(`${origin}/api/lsa/account/disputes/${data.customer_id}`))
          break
        default:
          axios
            .post(`${origin}/database/request`, {
              method: 'POST',
              url: `/api/lsa/account/disputes`,
              data: data,
            })
            .then((res) => {
              if (res.data.reason !== 'access denied') {
                cache.setCacheV2(`${origin}/api/lsa/account/disputes/${data.customer_id}`, res.data)
              }
              resolve(res.data)
            })
            .catch((err) => {
              reject(err)
            })
      }
    })
  },
  getLSALeadData: (data, timeframe) => {
    return new Promise((resolve, reject) => {
      switch (cache.isValid(`${origin}/api/lsa/account/leads/${data.customer_id}/${timeframe}`)) {
        case true:
          resolve(
            cache.getCacheV2(`${origin}/api/lsa/account/leads/${data.customer_id}/${timeframe}`),
          )
          break
        default:
          axios
            .post(`${origin}/database/request`, {
              method: 'POST',
              url: `/api/lsa/account/leads`,
              data: data,
            })
            .then((res) => {
              if (res.data.reason !== 'access denied') {
                cache.setCacheV2(
                  `${origin}/api/lsa/account/leads/${data.customer_id}/${timeframe}`,
                  res.data,
                )
              }
              resolve(res.data)
            })
            .catch((err) => {
              reject(err)
            })
      }
    })
  },
  getLSACallRecordingList: (data) => {
    return new Promise((resolve, reject) => {
      switch (cache.isValid(`${origin}/api/lsa/calls/list/${data.customer_id}`)) {
        case true:
          resolve(cache.getCacheV2(`${origin}/api/lsa/calls/list/${data.customer_id}`))
          break
        default:
          axios
            .post(`${origin}/database/request`, {
              method: 'POST',
              url: `/api/lsa/calls/list`,
              data: data,
            })
            .then((res) => {
              if (res.data.reason !== 'access denied') {
                cache.setCacheV2(`${origin}/api/lsa/calls/list/${data.customer_id}`, res.data)
              }
              resolve(res.data)
            })
            .catch((err) => {
              reject(err)
            })
      }
    })
  },
  getLSACallRecording: (data) => {
    return new Promise((resolve, reject) => {
      axios
        .post(
          `${origin}/database/request`,
          {
            method: 'POST',
            url: `/api/lsa/calls/recording`,
            data: data,
          },
          { responseType: 'arraybuffer' },
        )
        .then((res) => {
          resolve(res.data)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  getLSAProduct: (id) => {
    return new Promise((resolve, reject) => {
      axios
        .post(`${origin}/database/request`, {
          method: 'POST',
          url: `/api/external/getProduct/${id}`,
        })
        .then((res) => {
          resolve(res.data)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  saveLSAProduct: (data) => {
    return new Promise((resolve, reject) => {
      axios
        .post(`${origin}/database/request`, {
          method: 'POST',
          url: `/api/external/addProduct`,
          data: data,
        })
        .then((res) => {
          resolve(res.data)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  updateLSAProduct: (id, data) => {
    return new Promise((resolve, reject) => {
      axios
        .post(`${origin}/database/request`, {
          method: 'POST',
          url: `/api/external/updateProduct/${id}`,
          data: data,
        })
        .then((res) => {
          resolve(res.data)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },

  // WebWork Professional
  getWBXOverview: (data, clientId, itemId) => {
    return new Promise((resolve, reject) => {
      const url = `${origin}/api/uni/products/webworx/${clientId}/${itemId}`
      axios
        .post(`${origin}/database/request`, {
          method: 'POST',
          url: `/api/uni/products/webworx/${clientId}/${itemId}`,
          data: { ...data, origin },
        })
        .then((res) => {
          if (res.data.reason !== 'access denied') {
            cache.setCache(url, res.data)
          }
          resolve(res.data)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  getWebPerformance: (id) => {
    return new Promise((resolve, reject) => {
      axios
        .post(`${origin}/database/request`, {
          method: 'POST',
          url: `/api/external/getWebPerformance/${id}`,
        })
        .then((res) => {
          resolve(res.data)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  saveWebPerformance: (data) => {
    return new Promise((resolve, reject) => {
      axios
        .post(`${origin}/database/request`, {
          method: 'POST',
          url: `/api/external/addWebPerformance`,
          data: data,
        })
        .then((res) => {
          resolve(res.data)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  updateWebPerformance: (id, data) => {
    return new Promise((resolve, reject) => {
      axios
        .post(`${origin}/database/request`, {
          method: 'POST',
          url: `/api/external/updateWebPerformance/${id}`,
          data: data,
        })
        .then((res) => {
          resolve(res.data)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },

  // Website Hosting
  getWebHostOverview: (data, clientId, itemId) => {
    return new Promise((resolve, reject) => {
      switch (cache.isValid(`/api/uni/products/hosting/${clientId}/${itemId}`)) {
        case true:
          resolve(cache.getCache(`/api/uni/products/hosting/${clientId}/${itemId}`))
          break
        default:
          axios
            .post(`${origin}/database/request`, {
              method: 'POST',
              url: `/api/uni/products/hosting/${clientId}/${itemId}`,
              data: { ...data, origin },
            })
            .then((res) => {
              if (res.data.reason !== 'access denied') {
                cache.setCache(`/api/uni/products/hosting/${clientId}/${itemId}`, res.data)
              }
              resolve(res.data)
            })
            .catch((err) => {
              reject(err)
            })
      }
    })
  },

  // Website
  getWebsiteOverview: (data, clientId, itemId) => {
    return new Promise((resolve, reject) => {
      axios
        .post(`${origin}/database/request`, {
          method: 'POST',
          url: `/api/uni/products/website/${clientId}/${itemId}`,
          data: { ...data, origin },
        })
        .then((res) => {
          if (res.data.reason !== 'access denied') {
            cache.setCache(`/api/uni/products/website/${clientId}/${itemId}`, res.data)
          }
          resolve(res.data)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },

  // Powerpage
  getPowerPageOverview: (data, clientId, itemId) => {
    return new Promise((resolve, reject) => {
      axios
        .post(`${origin}/database/request`, {
          method: 'POST',
          url: `/api/uni/products/powerpage/${clientId}/${itemId}`,
          data: { ...data, origin },
        })
        .then((res) => {
          if (res.data.reason !== 'access denied') {
            cache.setCache(`/api/uni/products/powerpage/${clientId}/${itemId}`, res.data)
          }
          resolve(res.data)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },

  // OMGinContact
  getOMGinContactOverview: (data, clientId, itemId) => {
    return new Promise((resolve, reject) => {
      switch (cache.isValid(`/api/uni/products/omgincontact/${clientId}/${itemId}`)) {
        case true:
          resolve(cache.getCache(`/api/uni/products/omgincontact/${clientId}/${itemId}`))
          break
        default:
          axios
            .post(`${origin}/database/request`, {
              method: 'POST',
              url: `/api/uni/products/omgincontact/${clientId}/${itemId}`,
              data: { ...data, origin },
            })
            .then((res) => {
              if (res.data.reason !== 'access denied') {
                cache.setCache(`/api/uni/products/omgincontact/${clientId}/${itemId}`, res.data)
              }
              resolve(res.data)
            })
            .catch((err) => {
              reject(err)
            })
      }
    })
  },

  // Review Solicitation
  getReviewSolicitation: (data, clientId, query) => {
    return new Promise((resolve, reject) => {
      axios
        .post(`${origin}/database/request`, {
          method: 'POST',
          url: `/api/uni/products/reviewsolicitation/${clientId}${query}`,
          data: { ...data, origin },
        })
        .then((res) => {
          resolve(res.data)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  addReviewSolicitations: (data, clientId, item_id) => {
    return new Promise((resolve, reject) => {
      axios
        .post(`${origin}/database/request`, {
          method: 'POST',
          url: `/api/uni/products/reviewsolicitation/${clientId}/${item_id}/add`,
          data: { ...data, origin },
        })
        .then((res) => {
          console.log(res, 'res? add ')
          resolve(res.data)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },

  // Google Q&A
  getGoogleQA: (data, clientId) => {
    return new Promise((resolve, reject) => {
      switch (cache.isValid(`/api/uni/products/googleqa/${clientId}`)) {
        case true:
          resolve(cache.getCache(`/api/uni/products/googleqa/${clientId}`))
          break
        default:
          axios
            .post(`${origin}/database/request`, {
              method: 'POST',
              url: `/api/uni/products/googleqa/${clientId}`,
              data: { ...data, origin },
            })
            .then((res) => {
              if (res.data.reason !== 'access denied') {
                cache.setCache(`/api/uni/products/googleqa/${clientId}`, res.data)
              }
              resolve(res.data)
            })
            .catch((err) => {
              reject(err)
            })
      }
    })
  },

  // Reviews
  getReviews: (data, clientId, skipCache = false) => {
    return new Promise((resolve, reject) => {
      const url = `/api/uni/products/reviews/${clientId}`
      switch (cache.isValid(url)) {
        case true: {
          const cacheData = cache.getCache(url)
          resolve(cacheData)
          break
        }
        default:
          axios
            .post(`${origin}/database/request`, {
              method: 'POST',
              url: url,
              data: { ...data, origin },
            })
            .then((res) => {
              if (res.data.reason !== 'access denied') {
                try {
                  if (!skipCache) {
                    cache.setCache(url, res.data)
                  }
                } catch (e) {
                  console.error(e)
                }
              }
              resolve(res.data)
            })
            .catch((err) => {
              reject(err)
            })
      }
    })
  },

  // Social Posts
  getSocialPosts: (data, clientId) => {
    return new Promise((resolve, reject) => {
      axios
        .post(`${origin}/database/request`, {
          method: 'POST',
          url: `/api/uni/products/socialposts/${clientId}`,
          data: { ...data, origin },
        })
        .then((res) => {
          resolve(res.data)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  getFacebookSocialPosts: (data, clientId) => {
    return new Promise((resolve, reject) => {
      const url = `/api/uni/products/socialposts/facebook/${clientId}`
      axios
        .post(`${origin}/database/request`, {
          method: 'POST',
          url: url,
          data: { ...data, origin },
        })
        .then((res) => {
          if (res.data.reason !== 'access denied') {
            resolve(res.data)
          }
          reject(Error('Access is denied'))
        })
        .catch((err) => {
          console.error(err)
        })
    })
  },
  approveSocialPosts: (client_id, data) => {
    return new Promise((resolve, reject) => {
      const url = `/api/uni/client/notifications/${client_id}/approve`
      axios
        .post(`${origin}/database/request`, {
          method: 'POST',
          url: url,
          data: { ...data, origin },
        })
        .then((res) => {
          resolve(res)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },

  // Listings
  getListings: (data, clientId) => {
    return new Promise((resolve, reject) => {
      switch (cache.isValid(`/api/uni/products/listings/${clientId}`)) {
        case true:
          resolve(cache.getCache(`/api/uni/products/listings/${clientId}`))
          break
        default:
          axios
            .post(`${origin}/database/request`, {
              method: 'POST',
              url: `/api/uni/products/listings/${clientId}`,
              data: { email: data?.email, origin },
            })
            .then((res) => {
              if (res.data.reason !== 'access denied') {
                cache.setCache(`/api/uni/products/listings/${clientId}`, res.data)
              }
              resolve(res.data)
            })
            .catch((err) => {
              reject(err)
            })
      }
    })
  },
  getListingsData: (data, clientId) => {
    return new Promise((resolve, reject) => {
      switch (cache.isValid(`/api/uni/products/listings/data/${clientId}`)) {
        case true:
          resolve(cache.getCache(`/api/uni/products/listings/data/${clientId}`))
          break
        default:
          axios
            .post(`${origin}/database/request`, {
              method: 'POST',
              url: `/api/uni/products/listings/data/${clientId}`,
              data: { email: data?.email, origin },
            })
            .then((res) => {
              if (res.data.reason !== 'access denied') {
                cache.setCache(`/api/uni/products/listings/data/${clientId}`, res.data)
              }
              resolve(res.data)
            })
            .catch((err) => {
              reject(err)
            })
      }
    })
  },

  // Google Photos
  getGooglePhotos: (data, clientId) => {
    return new Promise((resolve, reject) => {
      switch (cache.isValid(`${origin}/api/uni/products/googlephotos/${clientId}`)) {
        case true:
          resolve(cache.getCache(`${origin}/api/uni/products/googlephotos/${clientId}`))
          break
        default:
          axios
            .post(`${origin}/database/request`, {
              method: 'POST',
              url: `/api/uni/products/googlephotos/${clientId}`,
              data: { ...data, origin },
            })
            .then((res) => {
              if (res.data.reason !== 'access denied') {
                cache.setCache(`${origin}/api/uni/products/googlephotos/${clientId}`, res.data)
              }
              resolve(res.data)
            })
            .catch((err) => {
              reject(err)
            })
      }
    })
  },

  // QR Codes
  getQRClient: (clientId) => {
    return new Promise((resolve, reject) => {
      switch (cache.isValid(`${origin}/api/beaconstac/client/${clientId}`)) {
        case true:
          resolve(cache.getCache(`${origin}/api/beaconstac/client/${clientId}`))
          break
        default:
          axios
            .post(`${origin}/database/request`, {
              method: 'GET',
              url: `/api/beaconstac/client/${clientId}`,
            })
            .then((res) => {
              cache.setCache(`${origin}/api/beaconstac/client/${clientId}`, res.data)
              resolve(res.data)
            })
            .catch((err) => {
              reject(err)
            })
      }
    })
  },
  getQRAccount: (clientId, productId) => {
    return new Promise((resolve, reject) => {
      switch (cache.isValid(`${origin}/api/beaconstac/account/${clientId}/${productId}`)) {
        case true:
          resolve(cache.getCache(`${origin}/api/beaconstac/account/${clientId}/${productId}`))
          break
        default:
          axios
            .post(`${origin}/database/request`, {
              method: 'GET',
              url: `/api/beaconstac/account/${clientId}/${productId}`,
            })
            .then((res) => {
              cache.setCache(`${origin}/api/beaconstac/account/${clientId}/${productId}`, res.data)
              resolve(res.data)
            })
            .catch((err) => {
              reject(err)
            })
      }
    })
  },
  getQRCode: (id) => {
    return new Promise((resolve, reject) => {
      switch (cache.isValid(`${origin}/api/beaconstac/qrcode/${id}`)) {
        case true:
          resolve(cache.getCache(`${origin}/api/beaconstac/qrcode/${id}`))
          break
        default:
          axios
            .post(`${origin}/database/request`, {
              method: 'GET',
              url: `/api/beaconstac/qrcode/${id}`,
            })
            .then((res) => {
              cache.setCache(`${origin}/api/beaconstac/qrcode/${id}`, res.data)
              resolve(res.data)
            })
            .catch((err) => {
              reject(err)
            })
      }
    })
  },
  getOSDistribution: (body, timeframe) => {
    return new Promise((resolve, reject) => {
      switch (cache.isValid(`${origin}/api/beaconstac/analytics/os/${timeframe}`)) {
        case true:
          resolve(cache.getCache(`${origin}/api/beaconstac/analytics/os/${timeframe}`))
          break
        default:
          axios
            .post(`${origin}/database/request`, {
              method: 'POST',
              url: `/api/beaconstac/analytics/os`,
              data: body,
            })
            .then((res) => {
              cache.setCache(`${origin}/api/beaconstac/analytics/os/${timeframe}`, res.data)
              resolve(res.data)
            })
            .catch((err) => {
              reject(err)
            })
      }
    })
  },
  getVisitorDistribution: (body, timeframe) => {
    return new Promise((resolve, reject) => {
      switch (cache.isValid(`${origin}/api/beaconstac/analytics/visitors/${timeframe}`)) {
        case true:
          resolve(cache.getCache(`${origin}/api/beaconstac/analytics/visitors/${timeframe}`))
          break
        default:
          axios
            .post(`${origin}/database/request`, {
              method: 'POST',
              url: `/api/beaconstac/analytics/visitors`,
              data: body,
            })
            .then((res) => {
              cache.setCache(`${origin}/api/beaconstac/analytics/visitors/${timeframe}`, res.data)
              resolve(res.data)
            })
            .catch((err) => {
              reject(err)
            })
      }
    })
  },
  getCityDistribution: (body, timeframe) => {
    return new Promise((resolve, reject) => {
      switch (cache.isValid(`${origin}/api/beaconstac/analytics/city/${timeframe}`)) {
        case true:
          resolve(cache.getCache(`${origin}/api/beaconstac/analytics/city/${timeframe}`))
          break
        default:
          axios
            .post(`${origin}/database/request`, {
              method: 'POST',
              url: `/api/beaconstac/analytics/city`,
              data: body,
            })
            .then((res) => {
              cache.setCache(`${origin}/api/beaconstac/analytics/city/${timeframe}`, res.data)
              resolve(res.data)
            })
            .catch((err) => {
              reject(err)
            })
      }
    })
  },
  getLocationDistribution: (body, timeframe) => {
    return new Promise((resolve, reject) => {
      switch (cache.isValid(`${origin}/api/beaconstac/analytics/locations/${timeframe}`)) {
        case true:
          resolve(cache.getCache(`${origin}/api/beaconstac/analytics/locations/${timeframe}`))
          break
        default:
          axios
            .post(`${origin}/database/request`, {
              method: 'POST',
              url: `/api/beaconstac/analytics/locations`,
              data: body,
            })
            .then((res) => {
              cache.setCache(`${origin}/api/beaconstac/analytics/locations/${timeframe}`, res.data)
              resolve(res.data)
            })
            .catch((err) => {
              reject(err)
            })
      }
    })
  },

  // CTM
  getCTMData: (data, clientId) => {
    return new Promise((resolve, reject) => {
      axios
        .post(`${origin}/database/request`, {
          method: 'POST',
          url: `/api/uni/products/ctm/${clientId}`,
          data: { ...data, origin },
        })
        .then((res) => {
          if (res.data.reason !== 'access denied') {
            cache.setCache(`${origin}/api/uni/products/ctm/${clientId}/${data}`, res.data)
          }
          resolve(res.data)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },

  // Charts
  getChartData: (data, clientId, query) => {
    return new Promise((resolve, reject) => {
      switch (cache.isValid(`${origin}/api/uni/charts/overview/${clientId}${query}`)) {
        case true:
          resolve(cache.getCache(`${origin}/api/uni/charts/overview/${clientId}${query}`))
          break
        default:
          axios
            .post(`${origin}/database/request`, {
              method: 'POST',
              url: `/api/uni/charts/overview/${clientId}${query}`,
              data: { ...data, origin },
            })
            .then((res) => {
              if (res.data.reason !== 'access denied') {
                cache.setCache(`${origin}/api/uni/charts/overview/${clientId}${query}`, res.data)
              }
              resolve(res.data)
            })
            .catch((err) => {
              reject(err)
            })
      }
    })
  },

  // Billing
  getBilling: (data, clientId) => {
    return new Promise((resolve, reject) => {
      switch (cache.isValid(`${origin}/api/uni/client/billing/${clientId}`)) {
        case true:
          resolve(cache.getCache(`${origin}/api/uni/client/billing/${clientId}`))
          break
        default:
          axios
            .post(`${origin}/database/request`, {
              method: 'POST',
              url: `/api/uni/client/billing/${clientId}`,
              data: { ...data, origin },
            })
            .then((res) => {
              if (res.data.reason !== 'access denied') {
                cache.setCache(`${origin}/api/uni/client/billing/${clientId}`, res.data)
              }
              resolve(res.data)
            })
            .catch((err) => {
              reject(err)
            })
      }
    })
  },

  // Geo Storage
  createGeoStorage: (data) => {
    return new Promise((resolve, reject) => {
      axios
        .post(`${origin}/database/request`, {
          method: 'POST',
          url: `/api/external/addCIDLocations`,
          data,
        })
        .then((res) => {
          resolve(res.data)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  getGeoStorage: (index) => {
    return new Promise((resolve, reject) => {
      axios
        .post(`${origin}/database/request`, {
          method: 'POST',
          url: `/api/external/getGeoStorage/${index}`,
        })
        .then((res) => {
          resolve(res.data)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  updateGeoStorage: (index, data) => {
    return new Promise((resolve, reject) => {
      axios
        .post(`${origin}/database/request`, {
          method: 'POST',
          url: `/api/external/updateGeoStorage/${index}`,
          data,
        })
        .then((res) => {
          resolve(res.data)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },

  // Constant Contact
  getCCMasterToken: () => {
    return new Promise((res, rej) => {
      axios
        .post(`${origin}/database/request`, { method: 'GET', url: `/api/external/cc/master/token` })
        .then((data) => {
          res(data)
        })
        .catch((err) => {
          rej(err)
        })
    })
  },
  createCCUser: (data) => {
    return new Promise((resolve, reject) => {
      axios
        .post(`${origin}/database/request`, {
          method: 'POST',
          url: `/api/external/ccuser/create`,
          data,
        })
        .then((res) => {
          resolve(res.data)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  updateCCUser: (id, data) => {
    return new Promise((resolve, reject) => {
      axios
        .post(`${origin}/database/request`, {
          method: 'PUT',
          url: `/api/external/ccuser/update/${id}`,
          data,
        })
        .then((res) => {
          resolve(res.data)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  getCCUni: (id, item_id, email) => {
    return new Promise((resolve, reject) => {
      axios
        .post(`${origin}/database/request`, {
          method: 'POST',
          url: `/api/uni/products/omgincontact/${id}/${item_id}`,
          data: { email, origin },
        })
        .then((res) => {
          resolve(res.data)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  getCCUser: (id) => {
    return new Promise((resolve, reject) => {
      switch (cache.isValid(`${origin}/api/external/ccuser/get/${id}`)) {
        case true:
          resolve(cache.getCache(`${origin}/api/external/ccuser/get/${id}`))
          break
        default:
          axios
            .post(`${origin}/database/request`, {
              method: 'GET',
              url: `/api/external/ccuser/get/${id}`,
            })
            .then((res) => {
              cache.setCache(`${origin}/api/external/ccuser/get/${id}`, res.data)
              resolve(res.data)
            })
            .catch((err) => {
              reject(err)
            })
      }
    })
  },
  createCCData: (data) => {
    return new Promise((resolve, reject) => {
      axios
        .post(`${origin}/database/request`, {
          method: 'POST',
          url: `/api/external/ccdata/create`,
          data,
        })
        .then((res) => {
          resolve(res.data)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  updateCCData: (id, data) => {
    return new Promise((resolve, reject) => {
      axios
        .post(`${origin}/database/request`, {
          method: 'PUT',
          url: `/api/external/ccdata/update/${id}`,
          data,
        })
        .then((res) => {
          resolve(res.data)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  getCCData: (id) => {
    return new Promise((resolve, reject) => {
      axios
        .post(`${origin}/database/request`, {
          method: 'GET',
          url: `/api/external/ccdata/get/${id}`,
        })
        .then((res) => {
          resolve(res.data)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  getAdSpec: (data, clientId) => {
    return new Promise((resolve, reject) => {
      const url = `${origin}/api/uni/products/adspec/${clientId}`
      axios
        .post(`${origin}/database/request`, {
          method: 'POST',
          url: `/api/uni/products/adspec/${clientId}`,
          data: { ...data, origin },
        })
        .then((res) => {
          if (res.data.reason !== 'access denied') {
            cache.setCache(url, res.data)
          }
          resolve(res.data)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  // CID Locations
  createCIDLocations: (data) => {
    return new Promise((resolve, reject) => {
      axios
        .post(`${origin}/database/request`, {
          method: 'POST',
          url: `/api/external/addGeoStorage`,
          data,
        })
        .then((res) => {
          resolve(res.data)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  getCIDLocations: () => {
    return new Promise((resolve, reject) => {
      axios
        .post(`${origin}/database/request`, { method: 'GET', url: `/api/external/getCIDLocations` })
        .then((res) => {
          resolve(res.data)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  updateCIDLocations: (data) => {
    return new Promise((resolve, reject) => {
      axios
        .post(`${origin}/database/request`, {
          method: 'POST',
          url: `/api/external/updateCIDLocations`,
          data,
        })
        .then((res) => {
          resolve(res.data)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },

  // Logs needs to be redirected to live
  createLiveSiteErrors: (errorBody) => {
    axios.post(`https://omginsite.com/database/request`, {
      method: 'POST',
      url: `/api/status/create/error`,
      data: errorBody,
    })
  },
  updateLiveSiteErrorLogs: (message, pageformat, data) => {
    axios.post(`https://omginsite.com/database/request`, {
      method: 'PUT',
      url: `/api/status/update/error/${message}/${pageformat}`,
      data,
    })
  },
  getOneLiveSiteErrorLogs: (message, pageformat) => {
    return new Promise((resolve, reject) => {
      axios
        .post(`https://omginsite.com/database/request`, {
          method: 'GET',
          url: `/api/status/finderror/${message}/${pageformat}`,
        })
        .then((x) => {
          resolve(x)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  getAllLiveSiteErrors: () => {
    return new Promise((resolve, reject) => {
      axios
        .post(`https://omginsite.com/database/request`, {
          method: 'GET',
          url: `/api/status/finderror/all`,
        })
        .then((x) => {
          resolve(x)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  updateByIdLiveSiteError: (id, body) => {
    return new Promise((resolve, reject) => {
      axios
        .post(`https://omginsite.com/database/request`, {
          method: 'PUT',
          url: `/api/status/finderror/updateid/${id}`,
          data: body,
        })
        .then((x) => {
          resolve(x)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  getListingsDBClient: (id) => {
    return new Promise((resolve, reject) => {
      axios
        .post(`${origin}/database/request`, {
          method: 'GET',
          url: `/api/external/listing/get/${id}`,
        })
        .then((res) => {
          resolve(res.data)
        })
        .catch((err) => {
          resolve({})
          //reject(err)
        })
    })
  },
  createListingsDBClient: (data) => {
    return new Promise((resolve, reject) => {
      axios
        .post(`${origin}/database/request`, {
          method: 'POST',
          url: `/api/external/listing/create`,
          data,
        })
        .then((res) => {
          resolve(res.data)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  updateListingsDBClient: (id, data) => {
    return new Promise((resolve, reject) => {
      axios
        .post(`${origin}/database/request`, {
          method: 'PUT',
          url: `/api/external/listing/update/${id}`,
          data,
        })
        .then((res) => {
          resolve(res.data)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  getListingsDBStorage: (id) => {
    return new Promise((resolve, reject) => {
      axios
        .post(`${origin}/database/request`, {
          method: 'GET',
          url: `/api/external/listingdata/get/${id}`,
        })
        .then((res) => {
          resolve(res.data)
        })
        .catch((err) => {
          resolve({})
          //reject(err)
        })
    })
  },
  createListingsDBStorage: (data) => {
    return new Promise((resolve, reject) => {
      axios
        .post(`${origin}/database/request`, {
          method: 'POST',
          url: `/api/external/listingdata/create`,
          data,
        })
        .then((res) => {
          resolve(res.data)
        })
        .catch((err) => {
          console.log(err, 'rrr?', data)
          reject(err)
        })
    })
  },
  updateListingsDBStorage: (id, data) => {
    return new Promise((resolve, reject) => {
      axios
        .post(`${origin}/database/request`, {
          method: 'PUT',
          url: `/api/external/listingdata/update/${id}`,
          data,
        })
        .then((res) => {
          resolve(res.data)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  // External
  searchScrapFlyAPI: (data) => {
    return new Promise((resolve, reject) => {
      axios
        .post(`${origin}/database/request`, {
          method: 'POST',
          url: `/api/external/search/scrapefly`,
          data,
        })
        .then((res) => {
          resolve(res.data)
        })
        .catch((err) => {
          console.log(err, 'error')
          resolve({})
        })
    })
  },
  searchGoogle: (data, type, cacheref) => {
    return new Promise((resolve, reject) => {
      if (cacheref === undefined) {
        axios
          .post(`${origin}/database/request`, { method: 'POST', url: `/api/external/search`, data })
          .then((res) => {
            resolve(res.data)
          })
          .catch((err) => {
            reject(err)
          })
      } else {
        switch (cache.isValid(cacheref)) {
          case true:
            resolve(cache.getCache(cacheref))
            break
          default:
            axios
              .post(`${origin}/database/request`, {
                method: 'POST',
                url: `/api/external/search`,
                data,
              })
              .then((res) => {
                if (res.data.reason !== 'access denied') {
                  if (res?.data?.local_service_ads && type === 'lsa') {
                    cache.setCacheDay(cacheref, { local_service_ads: res.data?.local_service_ads })
                  } else if (!res?.data?.local_service_ads && type === 'lsa') {
                    cache.setCacheDay(cacheref, 'N/A')
                  }
                  // else if (type === undefined) {
                  //   //   cache.setCacheDay(cacheref, res.data)
                  // }
                }

                resolve(res.data)
              })
              .catch((err) => {
                reject(err)
              })
        }
      }
    })
  },
  searchSerpAPI: (data) => {
    return new Promise((resolve, reject) => {
      axios
        .post(`${origin}/database/request`, {
          method: 'GET',
          url: `/api/external/serpAPISearch`,
          data,
        })
        .then((res) => {
          resolve(res.data)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  searchSerpAPIAll: (data) => {
    return new Promise((resolve, reject) => {
      axios
        .post(`${origin}/database/request`, {
          method: 'GET',
          url: `/api/external/serpapi/all`,
          data,
        })
        .then((res) => {
          resolve(res.data)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  getPageSpeed: (data) => {
    return new Promise((resolve, reject) => {
      axios
        .get(
          `https://www.googleapis.com/pagespeedonline/v5/runPagespeed?url=${data.url}/&category=${data.category}&strategy=${data.device}&key=AIzaSyBqs2R46BBuA6zI9VrzWyNA-9xbB0w2qFQ`,
        )
        .then((res) => {
          resolve(res.data)
        })
        .catch((err) => {
          reject(err)
          throw err
        })
    })
  },
  ccAPIRequest: (url, method, queryArray, pathArray, ccChildAccountUrl, ccToken) => {
    const request = techPartnerReq(url, method, queryArray, pathArray, ccChildAccountUrl, ccToken)
    try {
      return new Promise((result) => {
        request
          .then((res) => {
            result(res)
          })
          .catch((err) => result(err))
      })
    } catch (error) {
      return 'errored'
    }
  },
}

export function dateStringsToServer(dateObj, trend) {
  if (dateObj)
    try {
      const { to, from } = dateObj
      switch (trend) {
        case true: {
          // Using luxon, set the trend to and from date to the start and end minus the difference in days
          const difference = DateTime.fromMillis(to).diff(DateTime.fromMillis(from)).milliseconds
          const toString = DateTime.fromMillis(from).toFormat('MM/dd/yyyy')
          const fromString = DateTime.fromMillis(from - difference).toFormat('MM/dd/yyyy')
          const dateParam = `date_range=custom&start_date=${fromString}&end_date=${toString}`
          return dateParam
        }
        default: {
          const toString = DateTime.fromMillis(to).toFormat('MM/dd/yyyy')
          const fromString = DateTime.fromMillis(from).toFormat('MM/dd/yyyy')
          const dateParam = `date_range=custom&start_date=${fromString}&end_date=${toString}`
          return dateParam
        }
      }
    } catch (error) {
      return ''
    }
}

export function dateObjectsToServer(dateObj, trend) {
  if (dateObj)
    try {
      const { to, from } = dateObj
      switch (trend) {
        case true: {
          // Using luxon, set the trend to and from date to the start and end minus the difference in days
          const difference = DateTime.fromMillis(to).diff(DateTime.fromMillis(from)).milliseconds
          const toString = DateTime.fromMillis(from).toFormat('yyyy-MM-dd')
          const fromString = DateTime.fromMillis(from - difference).toFormat('yyyy-MM-dd')
          const dateObject = { to_date: toString, from_date: fromString }
          return dateObject
        }
        default: {
          const toString = DateTime.fromMillis(to).toFormat('yyyy-MM-dd')
          const fromString = DateTime.fromMillis(from).toFormat('yyyy-MM-dd')
          const dateObject = { to_date: toString, from_date: fromString }
          return dateObject
        }
      }
    } catch (error) {
      return ''
    }
}

class CCPartnerQuery {
  constructor(opUrl, opRequestMethod, opQueriesParams, opPathParams) {
    this.account_operation_url = opUrl
    this.account_operation_method = opRequestMethod
    this.account_operation_query_parameters = opQueriesParams
    this.account_operation_path_parameters = opPathParams
    this.account_operation_headers = [
      {
        header_key: 'Accept',
        header_value: 'application/json',
      },
    ]
  }
}

async function techPartnerReq(url, method, query, path, ccChildAccountUrl, ccToken) {
  const child1partnerurl = ccChildAccountUrl
  await delayApiCall()
  try {
    return await axios.post(child1partnerurl, new CCPartnerQuery(url, method, query, path), ccToken)
  } catch (error) {
    return 'errored'
  }
}

async function delayApiCall() {
  await new Promise((res) => setTimeout(res, 25))
}

export default API

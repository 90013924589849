import CryptoJS from "crypto-js";

const decrypt = () => {
  const token = localStorage.getItem( "token" )
  if (token) {
    const bytes = CryptoJS.AES.decrypt(token, "omginsite-secrect");
    const decrypt = JSON.parse( bytes.toString( CryptoJS.enc.Utf8 ) );
    
    return decrypt;
  }
};

export default decrypt;

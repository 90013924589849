// React
import { useState, useEffect } from 'react'
import { Helmet } from 'react-helmet'
import PropTypes from 'prop-types'

// CoreUI
import {
  CRow,
  CCol,
  CFormSelect,
  CButton,
  CCard,
  CCardHeader,
  CCardBody,
  CCardFooter,
  CContainer,
  CAlert,
  CSpinner,
  CCardSubtitle,
} from '@coreui/react-pro'
import CIcon from '@coreui/icons-react'
import { cilSettings } from '@coreui/icons'

// App
import LocalRankRender from '../../../apps/local-rank/LocalRankRender'

// Functions
import { getGridPreview } from './functions/preview'
import { getZoom } from './functions/zoomCalculator'

// Utils
import API from '../../../utils/data/api'
import { useClientInfo } from '../../../utils/reduxHookSignals/clientInformation'

// Debugging
// import { missingGridsReport, findClientInList } from './debugging/gridDebugging'

// Style
import '../../../scss/_local_rank.scss'

function LocalRankLive() {
  const { clientDecryptSignal, clientStartingPoint } = useClientInfo()

  // Insite Data
  const decrypt = clientDecryptSignal.value
  const client = decrypt.client
  const email = clientStartingPoint.value?.email

  // UNI Data
  const [uniKeywords, setUniKeywords] = useState([])

  // Activation
  const [needsActivation, setNeedsActivation] = useState(false)
  const [activationStatus, setActivationStatus] = useState('warning')
  const [activating, setActivating] = useState(false)

  // Grid Settings
  const [selectedCompany, setSelectedCompany] = useState(Object.keys(client?.companies)[0])
  const [selectedAddress, setSelectedAddress] = useState(Object.keys(client?.addresses)[0])
  const [addressIndex, setAddressIndex] = useState(0)
  const [keywords, setKeywords] = useState([])
  const [selectedKeyword, setSelectedKeyword] = useState('')
  const [selectedRadius, setSelectedRadius] = useState('1mi')
  const [selectedGrid, setSelectedGrid] = useState('7x7')
  const [buttonDisabled, setButtonDisabled] = useState(false)

  // Request Data
  const [itemId, setItemId] = useState('')

  // Preview Render
  const [center, setCenter] = useState(null)
  const [nodes, setNodes] = useState([])
  const [zoom, setZoom] = useState(13)

  // Alert
  const [processing, setProcessing] = useState(false)
  const [success, setSuccess] = useState(false)
  const [error, setError] = useState(false)

  // Activate account
  const activateGridAccount = () => {
    setActivating(true)
    API.activateGridAccount({ client_id: client?.client_id })
      .then((res) => {
        if (res?.client_id === client?.client_id) {
          setActivationStatus('success')
        } else {
          setActivationStatus('error')
        }
        setActivating(false)
      })
      .catch(() => {
        setActivationStatus('error')
        setActivating(false)
      })
  }

  // Run grid
  const runGrid = () => {
    setProcessing(true)

    // Generate searches for batch
    const searchesArray = []
    for (let i = 0; i < nodes.length; i++) {
      searchesArray.push({
        search_type: 'places',
        q: selectedKeyword,
        location: `lat: ${nodes[i]?.lat}, lon: ${nodes[i]?.lng}, zoom: 20`,
        google_domain: 'google.com',
        gl: 'us',
        hl: 'en',
        time_period: 'last_hour',
        device: 'desktop',
        sort_by: 'relevance',
        custom_id: nodes[i].custom_id,
      })
    }

    // Determine origin
    const origin = window.location.origin.includes('localhost')
      ? 'https://dev.omginsite.com'
      : window.location.origin

    // Run grid
    const body = {
      client_id: client.client_id,
      item_id: itemId,
      company_name: client?.companies[selectedCompany]?.company,
      location:
        client.geo?.[selectedCompany]?.[addressIndex]?.street +
        client.geo?.[selectedCompany]?.[addressIndex]?.citystate,
      keyword: selectedKeyword,
      radius: selectedRadius,
      size: selectedGrid,
      zoom: zoom,
      center: center,
      ran_by: email,
      searches: searchesArray,
      request_from: origin,
    }

    API.sendLiveBatch(body)
      .then(() => {
        setButtonDisabled(true)
        setProcessing(false)
        setSuccess(true)
      })
      .catch(() => {
        setButtonDisabled(true)
        setProcessing(false)
        setError(true)
      })
  }

  // Initialization
  useEffect(() => {
    setNeedsActivation(false)
    API.getMonthySearches().then((res) => {
      Object.values(res?.clients)?.forEach((monthly) => {
        if (monthly.client_id === client?.client_id) {
          API.getRankClient(client?.client_id).then((res) => {
            if (!res) {
              setNeedsActivation(true)
            }
            API.getKeywords({ email: email }, client?.client_id).then((uni) => {
              setUniKeywords(uni.keywords)
            })
          })
        }
      })
    })
  }, [])

  // Find keywords for selected company and address
  useEffect(() => {
    uniKeywords.forEach((search) => {
      if (search.company_id === selectedCompany && search.address_id === selectedAddress) {
        setKeywords(search.keywords)
        setItemId(search.item_id)
      }
    })
  }, [uniKeywords, selectedCompany, selectedAddress])

  // Preview render handling
  useEffect(() => {
    if (selectedCompany && selectedAddress) {
      if (client.geo?.[selectedCompany]?.[addressIndex]) {
        setCenter({
          lat: client.geo?.[selectedCompany]?.[addressIndex]?.lat,
          lng: client.geo?.[selectedCompany]?.[addressIndex]?.lng,
        })
        setNodes(
          getGridPreview(
            client.geo?.[selectedCompany]?.[addressIndex]?.lat,
            client.geo?.[selectedCompany]?.[addressIndex]?.lng,
            selectedRadius,
            selectedGrid,
          ),
        )
        setZoom(getZoom(selectedRadius, selectedGrid))
      } else {
        setCenter(null)
        setNodes([])
      }
    } else {
      setCenter(null)
      setNodes([])
    }
    setButtonDisabled(false)
    setProcessing(false)
    setSuccess(false)
    setError(false)
  }, [selectedCompany, selectedAddress, selectedKeyword, selectedRadius, selectedGrid])

  // Debugging
  // useEffect(() => {
  //   missingGridsReport(1722470400000)
  // }, [])
  // useEffect(() => {
  //   findClientInList('1022463')
  // }, [])

  return (
    <CContainer style={{ paddingTop: '8px', paddingBottom: '8px' }}>
      <Helmet>
        <title>OMGinSite | Local Rank | Live</title>
      </Helmet>
      {needsActivation && activationStatus === 'warning' ? (
        <CRow>
          <CCol md={12}>
            <CAlert className="d-flex justify-content-between align-items-center" color="warning">
              <div>
                {activating
                  ? 'Activating OMG Local Rank Grids...'
                  : 'Welcome to OMG Local Rank Grids!'}
              </div>
              <div>
                {activating ? (
                  <CSpinner size="sm" />
                ) : (
                  <span
                    style={{
                      cursor: 'pointer',
                      textDecoration: 'underline',
                      fontWeight: 'bold',
                    }}
                    onClick={() => {
                      activateGridAccount()
                    }}
                  >
                    Click here to Activate Grids
                  </span>
                )}
              </div>
            </CAlert>
          </CCol>
        </CRow>
      ) : needsActivation && activationStatus === 'success' ? (
        <CRow>
          <CCol md={12}>
            <CAlert className="d-flex justify-content-between align-items-center" color="success">
              <div>OMG Local Rank Grids activated!</div>
              <div>
                <button
                  style={{ backgroundColor: 'transparent', border: '0px' }}
                  onClick={() => {
                    setNeedsActivation(false)
                  }}
                >
                  ✖
                </button>
              </div>
            </CAlert>
          </CCol>
        </CRow>
      ) : needsActivation && activationStatus === 'error' ? (
        <CRow>
          <CCol md={12}>
            <CAlert className="d-flex justify-content-between align-items-center" color="error">
              <div>Something went wrong. Please contact your DMS for more information.</div>
              <div>
                <button
                  style={{ backgroundColor: 'transparent', border: '0px' }}
                  onClick={() => {
                    setNeedsActivation(false)
                  }}
                >
                  ✖
                </button>
              </div>
            </CAlert>
          </CCol>
        </CRow>
      ) : (
        <></>
      )}
      {processing ? (
        <CRow>
          <CCol md={12}>
            <CAlert className="d-flex justify-content-between align-items-center" color="warning">
              <div>Processing local rank grid request...</div>
              <div>
                <CSpinner color="warning" size="sm" />
              </div>
            </CAlert>
          </CCol>
        </CRow>
      ) : success ? (
        <CRow>
          <CCol md={12}>
            <CAlert color="success">
              Your local rank grid is being run. You will be alerted once the process has completed.
            </CAlert>
          </CCol>
        </CRow>
      ) : error ? (
        <CRow>
          <CCol md={12}>
            <CAlert color="danger">Something went wrong. Please try again later.</CAlert>
          </CCol>
        </CRow>
      ) : (
        <></>
      )}
      <CRow className="flex-row-reverse ">
        <CCol md={3} className="h-100 mb-3">
          <CCard className="p-0">
            <CCardHeader>
              <div className="with-icon">
                <CIcon icon={cilSettings} />

                <span className="font-weight-bold">Settings</span>
              </div>
            </CCardHeader>
            <CCardBody className="p-0">
              <div className="mb-2" style={{ paddingTop: '12px' }}>
                <SettingStyle
                  {...{
                    title: 'Company',
                    item: (
                      <CFormSelect
                        className="local-rank-setting"
                        aria-label="company-select"
                        value={selectedCompany}
                        onChange={(e) => {
                          setSelectedCompany(e.target.value)
                          setSelectedAddress('')
                          setSelectedKeyword('')
                        }}
                        disabled={needsActivation}
                      >
                        <option value="">Select Company</option>
                        {Object.entries(client?.companies).map(([key, value]) => (
                          <option key={key} value={key}>
                            {value?.company}
                          </option>
                        ))}
                      </CFormSelect>
                    ),
                  }}
                />
              </div>
              <div className="mb-2">
                <SettingStyle
                  {...{
                    title: 'Address',
                    item: (
                      <CFormSelect
                        className="local-rank-setting"
                        aria-label="address-select"
                        value={`${selectedAddress}-${addressIndex}`}
                        onChange={(e) => {
                          setSelectedAddress(e.target.value.split('-')[0])
                          setAddressIndex(parseInt(e.target.value.split('-')[1]))
                          setSelectedKeyword('')
                        }}
                        disabled={selectedCompany === '' || needsActivation}
                      >
                        <option value="">Select Address</option>
                        {client.geo?.[selectedCompany]?.map((location, index) => {
                          return (
                            <option
                              key={index}
                              value={`${location.id}-${index}`}
                            >{`${location.street}, ${location.citystate}`}</option>
                          )
                        })}
                      </CFormSelect>
                    ),
                  }}
                />
              </div>
              <div className="mb-2">
                {}
                <SettingStyle
                  {...{
                    title: 'Keyword',
                    item: (
                      <CFormSelect
                        className="local-rank-setting"
                        aria-label="keyword-select"
                        value={selectedKeyword}
                        onChange={(e) => setSelectedKeyword(e.target.value)}
                        disabled={
                          selectedCompany === '' || selectedAddress === '' || needsActivation
                        }
                      >
                        <option value="">Select Keyword</option>
                        {keywords?.map((keyword, index) => {
                          return (
                            <option key={index} value={keyword}>
                              {keyword}
                            </option>
                          )
                        })}
                      </CFormSelect>
                    ),
                  }}
                />
              </div>
              <div className="mb-2">
                <SettingStyle
                  {...{
                    title: 'Radius',
                    item: (
                      <CFormSelect
                        className="local-rank-setting"
                        aria-label="radius-select"
                        value={selectedRadius}
                        onChange={(e) => setSelectedRadius(e.target.value)}
                        disabled={needsActivation}
                      >
                        <option value=".25mi">0.25 Miles</option>
                        <option value=".5mi">0.5 Miles</option>
                        <option value="1mi">1 Mile</option>
                      </CFormSelect>
                    ),
                  }}
                />
              </div>
              <div className="">
                <SettingStyle
                  {...{
                    title: 'Grid',
                    item: (
                      <CFormSelect
                        className="local-rank-setting"
                        aria-label="grid-select"
                        value={selectedGrid}
                        onChange={(e) => setSelectedGrid(e.target.value)}
                        disabled={needsActivation}
                      >
                        <option value="7x7">7 x 7</option>
                        <option value="9x9">9 x 9</option>
                        <option value="13x13">13 x 13</option>
                      </CFormSelect>
                    ),
                    className: 'p-2 pb-0 px-3',
                  }}
                />
              </div>
              <div>
                <div className="d-flex flex-column p-2 pt-0">
                  <CButton
                    color="primary"
                    className="mt-2"
                    disabled={
                      selectedCompany === '' ||
                      selectedAddress === '' ||
                      selectedKeyword === '' ||
                      selectedRadius === '' ||
                      selectedGrid === '' ||
                      buttonDisabled ||
                      needsActivation
                    }
                    onClick={() => runGrid()}
                  >
                    Get Local Rank
                  </CButton>
                </div>
              </div>
            </CCardBody>
            <CCardFooter />
          </CCard>
        </CCol>
        <CCol xl={9} className="mb-3">
          <LocalRankRender
            locations={nodes}
            height={'29.40rem'}
            center={center}
            zoom={zoom}
            keyword={selectedKeyword}
            preview={true}
            selectedGrid={selectedGrid}
          />
        </CCol>
      </CRow>
    </CContainer>
  )
}

const SettingStyle = ({ title, item, className }) => {
  let _className = className ? className : 'p-2 px-3'
  return (
    <div className="my-1">
      <CCardSubtitle className="bg-light px-3 py-1 mb-1">{title}</CCardSubtitle>
      <div className="d-flex flex-wrap flex-row flex-md-column justify-content-between">
        <div className={_className}>{item}</div>
      </div>
    </div>
  )
}

SettingStyle.propTypes = {
  title: PropTypes.any,
  item: PropTypes.any,
  className: PropTypes.string,
}

export default LocalRankLive

import { createContext, useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types'
import { FacebookAuthProvider, signInWithPopup, onAuthStateChanged, signOut } from 'firebase/auth'
import { facebookAuth } from '../firebase-facebook-config'

const FacebookAuthContext = createContext({
  currentUser: null,
  signInWithFacebook: () => Promise,
  facebookLogout: () => Promise,
})

export const useFacebookAuth = () => useContext(FacebookAuthContext)

export default function FacebookAuthContextProvider({ children }) {
  const [currentUser, setCurrentUser] = useState(null)

  function signInWithFacebook() {
    const facebookProvider = new FacebookAuthProvider()
    return signInWithPopup(facebookAuth, facebookProvider)
  }

  function facebookLogout() {
    return signOut(facebookAuth)
  }

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(facebookAuth, (user) => {
      setCurrentUser(user)
    })
    return () => {
      unsubscribe()
    }
  }, [])

  const value = {
    currentUser,
    signInWithFacebook,
    facebookLogout,
  }

  return <FacebookAuthContext.Provider value={value}>{children}</FacebookAuthContext.Provider>
}

FacebookAuthContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
}

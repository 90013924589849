
import { DateTime } from "luxon"





 const now = DateTime.now()
 const thirtyDaysAgo = DateTime.now().minus({ days: 30 })
 const sixtyDaysAgo = DateTime.now().minus({ days: 60 })
 const ninetyDaysAgo = DateTime.now().minus({ days: 90 })
 const oneEightyDaysAgo = DateTime.now().minus({ days: 180 })
 
  const previousMonth = DateTime.now().minus({ months: 1 })
  const twoMonthsAgo = DateTime.now().minus({ months: 2 })
 const threeMonthsAgo = DateTime.now().minus({ months: 3 })

 const nowFormat = now.toFormat('MMM dd')
 const thirtyDaysAgoFormat = thirtyDaysAgo.toFormat('MMM dd')
 const sixtyDaysAgoFormat = sixtyDaysAgo.toFormat('MMM dd')
 const ninetyDaysAgoFormat = ninetyDaysAgo.toFormat('MMM dd')
 const oneEightyDaysAgoFormat = oneEightyDaysAgo.toFormat('MMM dd')
 
 const previousMonthFormat = previousMonth.toFormat('MMMM yyyy')
 const twoMonthsAgoFormat = twoMonthsAgo.toFormat('MMMM yyyy')
 const threeMonthsAgoFormat = threeMonthsAgo.toFormat('MMMM yyyy')

 export const millisecondConversion = {
    90: { from: ninetyDaysAgo.toMillis(), to: now.toMillis() },
    30: { from: thirtyDaysAgo.toMillis(), to: now.toMillis() },
    previous_month: {
      from: previousMonth.startOf('month').toMillis(),
      to: previousMonth.endOf('month').toMillis(),
    },
    '2_months_ago': {
      from: twoMonthsAgo.startOf('month').toMillis(),
      to: twoMonthsAgo.endOf('month').toMillis(),
    },
    last_month: {
      from: previousMonth.startOf('month').toMillis(),
      to: previousMonth.endOf('month').toMillis(),
    },

    custom: { from: 'custom', to: 'custom' },
  }


export const trendDateConversion = (dateSelectionFromCalendar) => {
    try {
         const { from, to } = dateSelectionFromCalendar
      const diff = to - from
      const newFrom = from - diff
      const newTo = to - diff
      return { from: newFrom, to: DateTime.fromMillis(newTo).minus({ days: 1 }).toMillis() }
    } catch (error) {
        console.log(error)
        return {from: 0, to: 0}
    }
}

export const trendAndMainDataResult = (dateSelectionFromCalendar) => {
    try {
        const idSetup = ''
      return {
    ids: [],
        currentTime: dateSelectionFromCalendar,
        trendTime: trendDateConversion(dateSelectionFromCalendar),
      }
    } catch (error) {
      return {}
    }
  }

 export const calendarDatesTemp =(dateSelectionFromCalendar) => {
  if(dateSelectionFromCalendar){
  const { to, from } = dateSelectionFromCalendar;
	const startDateObject = DateTime.fromMillis(from?.value || from).toJSDate();
	const endDateObject = DateTime.fromMillis(to?.value || to).toJSDate();
	return [startDateObject, endDateObject];
  }
  return []
}
// React
import { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

// CoreUI
import { CCard, CCardHeader, CCardBody, CCardFooter } from '@coreui/react-pro'
import CIcon from '@coreui/icons-react'
import { cilLocationPin, cilSearch } from '@coreui/icons'

// Google API
import Geocode from 'react-geocode'
import { GoogleMap, Marker } from '@react-google-maps/api'

// Data
import PercentageChart from './PercentageChart'
import ResultsGrid from './ResultsGrid'

// Style
import '../../scss/_local_rank.scss'
import TimeLineSlider from '../../components/slider/TimeLineSlider'
import { useSelector } from 'react-redux'

const MarkerList = ({ locations, setSelectedLocation }) => {
  return locations?.map((location, index) => {
    return (
      <MarkerWithInfoWindow
        key={index.toString()}
        location={location}
        index={index}
        setSelectedLocation={setSelectedLocation}
      />
    )
  })
}

const MarkerWithInfoWindow = ({ location, setSelectedLocation }) => {
  return (
    <Marker
      position={location}
      label={{
        text: location.label.text,
        color: location.label.color,
      }}
      icon={{
        path: window.google.maps.SymbolPath.CIRCLE,
        scale: 15,
        fillColor: location.icon.fillColor,
        fillOpacity: 1,
        strokeColor: location.icon.strokeColor,
        strokeWeight: 1,
      }}
      onClick={() => {
        if (location.data) {
          setSelectedLocation(location)
        }
      }}
    ></Marker>
  )
}

MarkerWithInfoWindow.propTypes = {
  location: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  setSelectedLocation: PropTypes.func.isRequired,
}

export const LocalRankRender = ({
  locations,
  height,
  center,
  zoom,
  keyword,
  preview,
  setCompanyName,
  setCompanyAddress,
  currentData,
  customGrid,
}) => {
  const [selectedLocation, setSelectedLocation] = useState(null)
  const [timeLineView, setTimeLineView] = useState(false)
  const [timeLineSelected, setTimelineSelected] = useState(false)
  const rankTimeLine = useSelector((state) => state.timeLineSettings)
  const checkHistoryDisplay = useSelector((state) => state.rankDisplay)

  // Authentication
  const apiKey = 'AIzaSyBqs2R46BBuA6zI9VrzWyNA-9xbB0w2qFQ'
  Geocode.setApiKey(apiKey)

  // Google Maps Component
  // const { isLoaded } = useJsApiLoader({
  //   id: 'google-map-script',
  //   googleMapsApiKey: apiKey,
  // })

  useEffect(() => {
    if (rankTimeLine.data) {
      setTimeLineView(true)
    } else {
      setTimeLineView(false)
    }
  }, [rankTimeLine])

  const DisplayPreview = (_location, _keyword) => {
    return (
      <div className="mt-4">
        <CCard className="mb-2">
          <CCardHeader>
            <div className="with-icon">
              <CIcon icon={cilSearch} />
              <span className="font-weight-bold">Results for: &apos;{_keyword}&apos;</span>
            </div>
          </CCardHeader>
          <CCardBody>
            <PercentageChart locations={_location} />
            <ResultsGrid
              location={selectedLocation}
              setCompanyName={setCompanyName}
              setCompanyAddress={setCompanyAddress}
              currentData={currentData}
            />
          </CCardBody>
          <CCardFooter></CCardFooter>
        </CCard>
      </div>
    )
  }

  return (
    <div>
      <CCard>
        <CCardHeader>
          <div className="with-icon">
            <CIcon icon={cilLocationPin} />
            <span className="font-weight-bold">Live Grid</span>
          </div>
        </CCardHeader>

        <CCardBody className="">
          {/* {rankTimeLine && timeLineSelected ? (
            <GoogleMap
              mapContainerStyle={{ height }}
              center={timeLineSelected?.center}
              zoom={timeLineSelected?.zoom}
            >
              {
                <MarkerList
                  locations={timeLineSelected?.grid_data}
                  setSelectedLocation={setSelectedLocation}
                />
              }
              <></>
            </GoogleMap>
          ) : checkHistoryDisplay ? (
            <>
              <GoogleMap
                mapContainerStyle={{ height }}
                center={checkHistoryDisplay?.data?.center}
                zoom={checkHistoryDisplay?.data?.zoom}
              >
                {
                  <MarkerList
                    locations={checkHistoryDisplay?.data?.grid_data}
                    setSelectedLocation={setSelectedLocation}
                  />
                }
                <></>
              </GoogleMap>
            </>
          ) : ( */}
          <>
            <GoogleMap mapContainerStyle={{ height }} center={center} zoom={zoom}>
              {<MarkerList locations={locations} setSelectedLocation={setSelectedLocation} />}
              <></>
            </GoogleMap>
          </>
          {/* )} */}

          {Object?.keys(rankTimeLine)?.length > 0 && rankTimeLine !== false ? (
            <div>
              <div className="px-5 py-2 pt-4 ">
                {!customGrid ? (
                  <TimeLineSlider
                    {...{
                      object: rankTimeLine?.data,
                      setNewGridData: setTimelineSelected,
                    }}
                  />
                ) : (
                  <></>
                )}
              </div>
            </div>
          ) : (
            <></>
          )}
        </CCardBody>
        <CCardFooter></CCardFooter>
      </CCard>

      {preview ? <></> : <>{DisplayPreview(locations, keyword)}</>}
      {/* {!checkHistoryDisplay ? (
        <></>
      ) : (
        <>
          {DisplayPreview(checkHistoryDisplay?.data?.grid_data, checkHistoryDisplay?.data?.keyword)}
        </>
      )}
      {Object?.keys(rankTimeLine)?.length > 0 &&
      rankTimeLine !== false &&
      timeLineSelected?.grid_data ? (
        <>{DisplayPreview(timeLineSelected?.grid_data, timeLineSelected?.keyword)}</>
      ) : (
        <></>
      )} */}
    </div>
  )
}

LocalRankRender.propTypes = {
  height: PropTypes.string,
  locations: PropTypes.array,
  center: PropTypes.object,
  zoom: PropTypes.number,
  keyword: PropTypes.string,
  preview: PropTypes.bool,
  selectedGrid: PropTypes.any,
  setCompanyName: PropTypes.func,
  setCompanyAddress: PropTypes.func,
  currentData: PropTypes.array,
  customGrid: PropTypes.bool,
}

LocalRankRender.defaultProps = {
  height: '400px',
}

export default LocalRankRender

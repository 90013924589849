import {
  configureStore,
  createAction,
  createAsyncThunk,
  createReducer,
  createSlice,
} from '@reduxjs/toolkit'
import { loadedClient } from './initializerFunctions'
import decrypt from '../utils/security/decrypt'
import { millisecondConversion, trendDateConversion } from '../utils/converters/temporaryConversions'

const { selectorCache, currentClient } = loadedClient()
// Redux store items, Parent and children key names must be unqiue to be targeted without issues.
// see existing all redux store action types console.log for example.

const storeItems = {
  // Development Toggle for easy live production disabling, use this variable, or use DevelopmentOnly.jsx component.
  isDevelopment: true,
  // Employee Related
  clientSelector: { ...selectorCache },
  clientDecrypt: { client: currentClient },
  clientStartingPoint: decrypt() || {},
  prospectData: { company: '', address: '' },
  salesGrids: [],
  // Header
  asideShow: false,
  theme: 'light',

  // Sidebar
  sidebarShow: window.innerWidth < 768 ? false : true,
  sidebarUnfoldable: false,

  // Overview cards
  businessCardToggle: false,

  // Billing Alerts
  approval: { selectedEditTopic: 'unselected' },
  notification: { notificationCount: 0, notificationStatus: {} },
  billAlert: { alert: false, count: 0 },

  // Grid Alerts
  gridAlerts: 0,
  gridAlertsData: [],
  needsActivation: false,

  // Ranks
  timeLineSettings: {},
  rankDisplay: {},
  rankGif: {
    pngLoading: false,
    rankGifLoading: false,
    gifPercent: 0,
  },

  // Filter
  filter: {
    customReactState: {},
    filterV2Checked: [],
    filterObj: {},
    defaultV2Counts: {},
    //custom
    dateApiFilterChanges:{ dateFilter:'90', range: millisecondConversion['90'], trend: trendDateConversion(millisecondConversion['90'])},
  },

  // Filter - Old
  filterBoxResult: [],
  dateSelectionFromCalendar: millisecondConversion['90'],

  // Performance Filter
  performanceFilter: {
    products: [],
    company: [],
    address: [],
    date: millisecondConversion['90'],
  },

  // Search Tool
  searchTool: {
    //in the case a similar name appears, I added a unique tag ST
    orderTypeST: 'newest',
    inputSearchST: '',
  },

  // Products
  products: {},

  // Misc
  link: { linkReference: { site: '', ref: '', clear: true } },
  reviewFormat: { reviewFormat: {} },
  // This is temp, was going to add this to temporary redux but i still need to improve the temporary states.
  reviewSolicitation: {
    selectedCompanyRS: '',
    selectedItemIdRS: '',
    phoneInputRS: '',
    firstNameInputRS: '',
    lastNameInputRS: '',
    chosenTemplateRS: '',
    csvBulkDataRS: [],
    toggledBulkUIRS: false,
    detectedNoPhoneNumberInBulkRS: false,
    solicitationContactTableList: [],
    noPhoneNumberDetectedRS: false,
    resetRS: false,
    scheduledDateRS: '',
    sendTypeRS: '',
    editContactRS: {},
    existingPhoneNumbersRS: []
  },

}

// Add restricted build case fields here, example items inside selectorCache or currentClient
let restrictedBuildCaseItems = [
  'clientId',
  'exp',
  'index',
  'clientLabel',
  'client',
  'accessToken',
  'admin',
  'auth_type',
  'clients',
  'data',
  'email',
  'firstName',
  'lastName',
  'perm',
  'phoneExt',
]

const createReducerItems = (items) => {
  let _reducerItems = {}
  let existing = new Set()
  let cases = []
  Object.entries(items).forEach((item) => {
    if (existing.has(item[0])) return
    const initialState = item[1]
    const reducer = createReducer(initialState, (builder) => {
      if (item[1] === undefined) return
      if (typeof item[1] === 'object' && Object.keys(item[1]).length >= 1) {
        Object.entries(item[1]).forEach((element) => {
          if (existing.has(element[0])) return
          if (restrictedBuildCaseItems.includes(element[0])) return
          existing.add(element[0])
          const _createdAction = createAction(element[0])
          builder.addCase(_createdAction, (state, action) => {
            state[action.type] = action.payload
            return state
          })
        })
      }
      if (!existing.has(item[0])) {
        const _createdAction = createAction(item[0])
        builder.addCase(_createdAction, (state, action) => {
          state = action.payload
          return state
        })
        existing.add(item[0])
      }
    })

    _reducerItems = { ..._reducerItems, [item[0]]: reducer }
  })
  return { reducerItems: _reducerItems, cases: cases }
}

const createTempBuildCases = (items) => {
  let initialState = {}
  let existing = new Set()
  let cases = []

  Object.entries(items).forEach(([key, value]) => {
    if (existing.has(key)) return

    const initialItemState = value
    const caseReducer = (state, action) => {
      state[key] = action.payload
    }

    initialState = { ...initialState, [key]: initialItemState }
    cases.push({ actionType: key, caseReducer })

    existing.add(key)
  })

  return { initialState, cases }
}

// Action creator for setting temporary state
const createTempActions = createAsyncThunk('tempState', async (payload, thunkAPI) => {
  const { initialState, cases } = createTempBuildCases(payload)
  const enhancedSlice = createEnhancedSlice(tempSlice, cases)
 // console.log(initialState, 'init')
  thunkAPI.dispatch(enhancedSlice.actions.setTempState(initialState))
  return initialState
})

const resetTempActions = createAsyncThunk('tempState', async (payload, thunkAPI) => {
  thunkAPI.dispatch(tempSlice.actions.resetTempState(payload))
})

// Reducer for temporary state
const createEnhancedSlice = (baseSlice, cases) => {
 // console.log(baseSlice, 'base?')
  const enhanceSlice = createSlice({
    name: 'tempState',
    initialState: {},
    reducers: {
      ...baseSlice.caseReducers,
    },
    extraReducers: (builder) => {
      if (cases.length === 0) {
        console.log('No cases provided for extraReducers.')
        return
      }
 
      cases.forEach(({ actionType, caseReducer }) => {

        builder.addCase(actionType, caseReducer)
      })
    },
  })
  //console.log(baseSlice, 'reduc?', enhanceSlice.reducer, 'rest')
  return enhanceSlice
}

const tempSlice = createSlice({
  name: 'tempState',
  initialState: {},
  reducers: {
    setTempState: (state, action) => {
      return { ...state, ...action.payload }
    },
    resetTempState: () => {
      return {}
    },
  },
})

const initSlice = createEnhancedSlice(tempSlice, [])

const reduxStore = configureStore({
  reducer: { ...createReducerItems(storeItems).reducerItems, ...{ tempState: initSlice.reducer } },
  devTools: false,
  //I'm using this to bypass some of the stuff that's being passed through redux
  //advised we either enable this only on dev and find other solutions, and set to false on live.
  middleware: getDefaultMiddleware =>
  getDefaultMiddleware({
    serializableCheck: false,
  }),
})

const reduxFormat = (type, payload) => {
  return { type: type, payload: payload }
}

function updateReduxStates(type, payload) {
  reduxStore.dispatch(reduxFormat(type, payload))
}

function createTempReduxStates(key, value) {
  reduxStore.dispatch(createTempActions({ [key]: value }))
}

function resetTempReduxStates() {
  reduxStore.dispatch(resetTempActions())
}

export { reduxStore, reduxFormat, updateReduxStates, createTempReduxStates, resetTempReduxStates }

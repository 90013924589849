import cache from "../utils/data/cache"
import decrypt from "../utils/security/decrypt"




function loadedClient() {
  const decrypting = decrypt()
    //old code converted to redux
    // isValid will overlook exisiting objects if an hour from login has surpassed. For now, code will check both until a better solution is found.
    const isCurrentKey = cache?.isValid(`${window.location.origin}, key`)
    if (isCurrentKey) {
      const getKey = JSON?.parse(localStorage.getItem('cache'))[`${window.location.origin}, key`]
  
      if (getKey) {
        const cacheKey = cache.getCache(`${window.location.origin}, key`)
        const clientId = cacheKey.clientId
        const client = cache.getCache(`${window.location.origin}/api/uni/client/${clientId}`)
        return { selectorCache: cacheKey, currentClient: client }
      }
    }
    const selectorCache = {
      clientId: decrypting?.client?.client_id || 'N/A',
      index: 0,
      client: decrypting?.client || 'N/A',
    }

    return { selectorCache:selectorCache, currentClient: decrypting?.client || 'N/A' }
  }
  
  export {loadedClient}
// React
import PropTypes from 'prop-types'

// CoreUI
import { CRow, CCol, CImage } from '@coreui/react-pro'

// Components
import CompetitorSpread from './CompetitorSpread'

// Style
import '../../scss/_local_rank.scss'

function ResultsGrid({ location }) {
  const starRating = (rating) => {
    const stringRating = rating.toFixed(1).toString()
    const ratingArray = stringRating.split('.')
    const stars = []
    let decimal = false
    for (let i = 1; i <= 5; i++) {
      if (parseInt(ratingArray[0]) >= i) {
        stars.push(<img src="/assets/icons/stars/star10.svg" width={30} />)
      } else if (!decimal) {
        stars.push(<img src={`/assets/icons/stars/star${ratingArray[1]}.svg`} width={30} />)
        decimal = true
      } else {
        stars.push(<img src="/assets/icons/stars/star0.svg" width={30} />)
      }
    }
    return (
      <div className="d-flex">
        {stars.map((star, index) => {
          return <div key={index}>{star}</div>
        })}
      </div>
    )
  }

  return (
    <>
      <div>
        {!location ? (
          <div className="mb-2" style={{ fontSize: '20px', fontWeight: 'bold' }}>
            Select a point to get local rank competitor data
          </div>
        ) : (
          <>
            <div className="d-flex align-items-center">
              <div style={{ marginRight: '0.5rem' }}>
                <img src="/assets/fontawesome/location-arrow.svg" width={20} />
              </div>
              <div style={{ marginTop: '0.1rem' }}>
                Search Location - Latitude: {location?.lat.toFixed(7)}, Longitude:{' '}
                {location?.lng.toFixed(7)}.
              </div>
            </div>
            {location?.data === 'ZERO_RESULTS' || location?.rank === 0 ? (
              <>
                <div className="mb-2 mt-4">
                  There are zero search results in this area based on your search terms and selected
                  filters. This could be a result of any of the following,
                </div>
                <div>
                  <span style={{ fontWeight: 'bold' }}>1.</span> An error or update occured on
                  Google Maps as the search call was being made resulting in a zero results status.
                </div>
                <div>
                  <span style={{ fontWeight: 'bold' }}>2.</span> There are actually no results in
                  the selected area due to enviornmental surroundings (open water, unpopulated area,
                  etc.)
                </div>
                <div className="mt-2">
                  Please try again in ten minutes to see if this area populates a rank, if the issue
                  persists then this area is most likely a &quot;dead zone&quot;
                </div>
              </>
            ) : (
              <>
                <CRow className="local-rank-row-border">
                  <span style={{ fontSize: '20px' }}>Keyword search results</span>
                </CRow>
                <CRow className="local-rank-row-border">
                  <CCol md={5}>
                    <div className="results-list">
                      {location?.data?.map((result, index) => {
                        return (
                          <CRow
                            className={`local-rank-row-border location-row ${
                              location?.rank === index + 1 ? 'selected-client-row' : ''
                            }`}
                            key={index}
                          >
                            <CRow>
                              <CCol md={4}>
                                <CImage
                                  src={result.thumbnail}
                                  alt={`thumbnail-position-${index}`}
                                  style={{ width: '100%', height: '100%', objectFit: 'contain' }}
                                  onError={(e) => {
                                    e.target.src =
                                      'https://via.placeholder.com/150?text=Image+Not+Found'
                                  }}
                                />
                              </CCol>
                              <CCol md={8}>
                                <div className="d-flex flex-column ">
                                  {/* <div>
                                <span
                                  className="map-icon"
                                  style={{
                                    backgroundColor: `${
                                      rankColors?.[index + 1] || rankColors['X']
                                    }`,
                                  }}
                                >
                                  {index + 1}
                                </span>
                              </div> */}
                                  <div>
                                    <span style={{ fontWeight: 'bold' }}>{result?.position}</span> -{' '}
                                    <span>{result?.title}</span>
                                  </div>
                                  <div>{result?.location || 'Service Area Business'}</div>
                                </div>
                              </CCol>
                            </CRow>
                            <CRow className="px-4">
                              <div className="d-flex flex-column ">
                                <div>
                                  <span style={{ fontWeight: 'bold' }}>Total Reviews</span> :{' '}
                                  {result?.reviews || 0}
                                </div>
                                <div className="d-flex align-items-center">
                                  <div>{starRating(result?.rating || 0)}</div>
                                  <div style={{ marginTop: '0.2rem', marginLeft: '0.25rem' }}>
                                    {result?.rating?.toFixed(1) || '0.0'}
                                  </div>
                                </div>
                              </div>
                            </CRow>
                          </CRow>
                        )
                      })}
                    </div>
                  </CCol>
                  <CCol md={7}>
                    <CompetitorSpread location={location} />
                  </CCol>
                </CRow>
              </>
            )}
          </>
        )}
      </div>
    </>
  )
}

ResultsGrid.propTypes = {
  location: PropTypes.object.isRequired,
  setCompanyName: PropTypes.func,
  setCompanyAddress: PropTypes.func,
  currentData: PropTypes.array,
}

export default ResultsGrid

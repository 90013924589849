import PropTypes from 'prop-types'
import { Slider, Handles, Tracks, Rail, Ticks } from 'react-compound-slider'
import { useEffect } from 'react'
import { useState } from 'react'
import changeDate from '../../utils/compatibility/datecompatibility'
import { CImage } from '@coreui/react-pro'
function TimeLineSlider({ object, setNewGridData, title }) {
  const [converter, setConverter] = useState(false)
  const [parser, setParser] = useState(false)
  const [nearest, setNearest] = useState(false)
  useEffect(() => {
    if (nearest) {
      if (setNewGridData) {
        setNewGridData(object[nearest])
      }
    }
  }, [nearest, converter])
  useEffect(() => {
    let parsedArray = []
    let converter = {}
    if (object)
      Object.values(object).forEach((element, index) => {
        let time = `${changeDate(new Date(element?.created_at), {}, true).outputDate} ${
          changeDate(new Date(element?.created_at), {}, true).outputTime
        }`
        converter = { ...converter, [(index + 1) * 25]: element }
        parsedArray.push((index + 1) * 25)
      })
    setConverter(converter)
    let sorted = parsedArray.sort((a, b) => {
      return a - b
    })
    setParser(sorted)
  }, [object])
  const sliderStyle = {
    // Give the slider some width
    position: 'relative',
    width: '100%',
    height: 80,
    //  border: '1px solid steelblue',
  }
  const railStyle = {
    position: 'absolute',
    width: '100%',
    height: 13,
    marginTop: 10,
    borderRadius: 5,
    backgroundColor: ' #45465c',
    opacity: '0.124',
  }
  function HandlePoint(data) {}
  if (!Object.values(object)[0].grid_data) return <></>
  else {
    return (
      <div className="pt-4" style={{ borderTop: '1px solid light' }}>
        <Slider
          rootStyle={sliderStyle}
          domain={[parser[0], parser[parser.length - 1]]}
          step={1}
          mode={2}
          values={[parser[0]]}
        >
          <Rail>{({ getRailProps }) => <div style={railStyle} {...getRailProps()} />}</Rail>
          <Handles>
            {({ handles, getHandleProps }) => (
              <div className="slider-handles">
                {handles.map((handle) => (
                  <Handle
                    key={handle.id}
                    handle={handle}
                    getHandleProps={getHandleProps}
                    parserConverter={converter}
                    parser={parser}
                    handlePoint={HandlePoint}
                    nearest={nearest}
                    setNearest={setNearest}
                  />
                ))}
              </div>
            )}
          </Handles>
          <Tracks left={false} right={false}>
            {({ tracks, getTrackProps }) => (
              <div className="slider-tracks">
                {tracks.map(({ id, source, target }) => (
                  <Track key={id} source={source} target={target} getTrackProps={getTrackProps} />
                ))}
              </div>
            )}
          </Tracks>

          <Ticks values={parser}>
            {({ ticks }) => (
              <div className="slider-ticks">
                {ticks.map((tick) => (
                  <>
                    <Tick
                      key={tick.id}
                      tick={tick}
                      count={ticks?.length}
                      parserConverter={converter}
                      length={parser?.length}
                    />
                  </>
                ))}
              </div>
            )}
          </Ticks>
        </Slider>
      </div>
    )
  }
}
TimeLineSlider.propTypes = {
  object: PropTypes.object,
  setNewGridData: PropTypes.func,
  title: PropTypes.any,
}
export function Handle({
  handle: { id, value, percent },
  getHandleProps,
  parserConverter,
  handlePoint,
  parser,
  nearest,
  setNearest,
}) {
  useEffect(() => {
    if (parser?.length > 0) {
      const nearestDate = parser.reduce((a, b) => {
        return Math.abs(b - value) < Math.abs(a - value) ? b : a
      })
      if (nearestDate !== nearest) {
        setNearest(nearestDate)
      }
    }
  }, [value, nearest, parser])

  let time = `${changeDate(new Date(parserConverter[nearest]?.created_at), {}, true).outputDate} ${
    changeDate(new Date(parserConverter[nearest]?.created_at), {}, true).outputTime
  }`
  return (
    <div
      style={{
        left: `${percent}%`,
        position: 'absolute',
        marginLeft: -15,
        marginTop: 0,
        zIndex: 2,
        width: 20,
        height: 20,
        border: 0,
        textAlign: 'center',
        cursor: 'pointer',
        borderRadius: '50%',
        //  backgroundColor: '#212233',
        color: '#333',
      }}
      {...getHandleProps(id)}
    >
      <div className="text-primary" style={{ fontFamily: 'Roboto', fontSize: 11, marginTop: -32 }}>
        <div
          className="d-flex justify-content-center"
          style={{ position: 'relative', left: '6px' }}
        >
          {time}
        </div>
        <CImage
          style={{ height: '32px', borderRadius: '100px', border: ' 2px solid #f0a244' }}
          src="./assets/omgsmall.jpg"
          alt="OMG"
        />
      </div>
    </div>
  )
}

Handle.propTypes = {
  handle: PropTypes.any,
  getHandleProps: PropTypes.any,
  parserConverter: PropTypes.any,
  handlePoint: PropTypes.func,
  parser: PropTypes.any,
  nearest: PropTypes.any,
  setNearest: PropTypes.any,
}
function Tick({ tick, count, parserConverter, length }) {
  let startEndTick = tick.percent === 0 || tick.percent === 100

  let time = `${
    changeDate(new Date(parserConverter?.[tick?.value]?.created_at), {}, true).outputDate
  } ${changeDate(new Date(parserConverter?.[tick?.value]?.created_at), {}, true).outputTime}`
  return (
    <div className="">
      <div
        style={{
          position: 'absolute',
          marginTop: 26,
          marginLeft: -0.5,
          width: startEndTick ? 2 : 2,
          height: startEndTick ? 14 : 5,
          backgroundColor: startEndTick ? 'black' : 'silver',
          left: `${tick.percent}%`,
        }}
      />

      <div
        style={{
          position: 'absolute',
          marginTop: 44,
          fontSize: 10,
          textAlign: length === 1 ? '' : 'center',
          marginLeft: length === 1 ? '-34px' : `${-(100 / count) / 2}%`,
          width: length === 1 ? '' : `${100 / count}%`,
          left: length === 1 ? '' : `${tick.percent}%`,
        }}
      >
        <div>
          {tick.percent === 0 || tick.percent === 100 ? (
            <div className="d-flex flex-wrap justify-content-center" style={{ fontSize: '14px' }}>
              {time?.split(' ')[0]}
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>
    </div>
  )
}
Tick.propTypes = {
  tick: PropTypes.any,
  count: PropTypes.any,
  parserConverter: PropTypes.any,
  length: PropTypes.any,
}
function Track({ source, target, getTrackProps }) {
  return (
    <div
      style={{
        position: 'absolute',
        height: 10,
        zIndex: 1,
        marginTop: 35,
        backgroundColor: '#f0a244',
        borderRadius: 5,
        cursor: 'pointer',
        left: `${source.percent}%`,
        width: `${target.percent - source.percent}%`,
      }}
      {
        ...getTrackProps() /* this will set up events if you want it to be clickeable (optional) */
      }
    />
  )
}

Track.propTypes = {
  source: PropTypes.any,
  target: PropTypes.any,
  getTrackProps: PropTypes.any,
}
export default TimeLineSlider

import decrypt from '../security/decrypt'
import API from '../data/api'
import cache from '../data/cache'
import encoder from '../security/encoder'
import getBrowser from '../security/browser'

export function createInsiteErrors(error, component) {


 if (window?.location?.href.includes('https://omginsite.com') ) {
    const clientid= cache?.getCache(`${window.location.origin}, keys`)?.clientId || `${decrypt()?.client?.client_id}?`
    const pageformat = window?.location?.hash?.replaceAll('#/', '')?.replaceAll('/', ',')
   
    const filterid = pageformat?.split(',')?.filter((path, index) => index === 2)

    const productid = filterid?.length > 0 ? encoder?.decode(filterid[0]) : false
    const _pageformat = forceReformat(pageformat?.split(',')?.map((path) =>{ 
      const isDecodeable = encoder.decode(path) !== ""
      return isDecodeable? '{id}': path}))
    
    const message = `${error?.message} - ({${_pageformat} : ${component}})`
    
    API.getOneLiveSiteErrorLogs(message, _pageformat).then((selectedErr) => {
      if (selectedErr?.data?.length === 0) {
        const errorBody = {
          message: message,
          page: _pageformat,
          stack: error.stack,
          component: component,
          affected: {
            clients: [clientid],
            emails: [decrypt()?.email],
            productids: productid? [productid] : [],
            browser:[getBrowser()],
            count: 1,
          },
          resolved: false,
        }
        API.createLiveSiteErrors(errorBody)
      } else {
        let clientsContainer = new Set()
        let emailsContainer = new Set()
        let productidContainer = new Set()
        let browserContainer = new Set()

        selectedErr?.data?.[0]?.affected?.browser?.forEach((x) => {
         browserContainer.add(x)
        })
        selectedErr?.data?.[0]?.affected?.productids?.forEach((x) => {
          productidContainer.add(x)
        })
        selectedErr?.data?.[0]?.affected?.clients?.forEach((x) => {
          clientsContainer.add(x)
        })
        selectedErr?.data?.[0]?.affected?.emails?.forEach((x) => {
          emailsContainer.add(x)
        })
        productidContainer.add(productid)
        clientsContainer.add(clientid)
        emailsContainer.add(decrypt()?.email)
        browserContainer.add(getBrowser())
        let currentAffectedCount = selectedErr?.data[0]?.affected?.count
        currentAffectedCount++
        API.updateLiveSiteErrorLogs(message, _pageformat, {
          affected: {
            clients: [...clientsContainer],
            productids: [...productidContainer],
            emails: [...emailsContainer],
            browser: [...browserContainer],
            count: currentAffectedCount,
          },
          resolved: false,
        })
      }
    })
  }

  return 'Error, Dev team notified.'
}

function forceReformat(str){

 const gbpPath = ['google-business-pro']
if(str.includes(gbpPath))
{
  return '/Products/google-business-pro/{id}'
}
else return str.join(',')
}


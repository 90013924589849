// Import the functions you need from the SDKs you need
import { initializeApp, getApps } from 'firebase/app'
import { getAnalytics } from 'firebase/analytics'
import { getAuth } from 'firebase/auth'
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseFacebookConfig = {
  apiKey: 'AIzaSyA5uGkEwOUr9npmooMkZc4d7mG_eJT35_Y',
  authDomain: 'omginsite-fb.firebaseapp.com',
  projectId: 'omginsite-fb',
  storageBucket: 'omginsite-fb.appspot.com',
  messagingSenderId: '171426599443',
  appId: '1:171426599443:web:c7d05a04bb8963e2b065e6',
  measurementId: 'G-5281W0NGYN',
}

// Initialize Firebase
const facebookApp = initializeApp(firebaseFacebookConfig, 'facebook')
const analytics = getAnalytics(facebookApp)

export const facebookAuth = getAuth(facebookApp)

const cypher = {
  hash: [
    'a',
    'b',
    'c',
    'd',
    'e',
    'f',
    'g',
    'h',
    'i',
    'j',
    'k',
    'l',
    'm',
    'n',
    'o',
    'p',
    'q',
    'r',
    's',
    't',
    'u',
    'v',
    'w',
    'x',
    'y',
    'z',
    'A',
    'B',
    'C',
    'D',
    'E',
    'F',
    'G',
    'H',
    'I',
    'J',
    'K',
    'L',
    'M',
    'N',
    'O',
    'P',
    'Q',
    'R',
    'S',
    'T',
    'U',
    'V',
    'W',
    'X',
    'Y',
    'Z',
    '1',
    '2',
    '3',
    '4',
    '5',
    '6',
    '7',
    '8',
    '9',
    '0',
  ],
  strategies: ['kTbyVfxNBg', 'QcAEuOvfiC', 'AybGzfMRwh'],
  cypher: {
    kTbyVfxNBg: {
      1: ['k', 'G', 'n', 'c', 'S', 'Y'],
      2: ['a', 'u', 'M', 'T', 'q'],
      3: ['Z', 'p', 'b', 'W', 'E'],
      4: ['Q', 'l', 'y', 'I', 'X'],
      5: ['d', 'r', 'A', 'V', 's'],
      6: ['m', 'f', 'C', 'H', 'o'],
      7: ['L', 'w', 'h', 'j', 'x'],
      8: ['F', 'z', 'N', 'K', 'i'],
      9: ['e', 'O', 't', 'B', 'R'],
      0: ['D', 'v', 'J', 'P', 'g', 'U'],
    },
    QcAEuOvfiC: {
      1: ['Q', 'l', 'y', 'I', 'X', 'S'],
      2: ['k', 'G', 'n', 'c', 'U'],
      3: ['d', 'r', 'A', 'V', 's'],
      4: ['Z', 'p', 'b', 'W', 'E'],
      5: ['a', 'u', 'M', 'T', 'q'],
      6: ['e', 'O', 't', 'B', 'R'],
      7: ['D', 'v', 'J', 'P', 'g'],
      8: ['m', 'f', 'Y', 'H', 'o'],
      9: ['F', 'z', 'N', 'K', 'i'],
      0: ['L', 'w', 'h', 'j', 'x', 'C'],
    },
    AybGzfMRwh: {
      1: ['d', 'r', 'A', 'V', 's', 'X'],
      2: ['Q', 'l', 'y', 'I', 'S'],
      3: ['Z', 'p', 'b', 'W', 'E'],
      4: ['k', 'G', 'n', 'c', 'U'],
      5: ['F', 'z', 'N', 'K', 'i'],
      6: ['m', 'f', 'Y', 'H', 'o'],
      7: ['a', 'u', 'M', 'T', 'q'],
      8: ['e', 'O', 't', 'B', 'R'],
      9: ['L', 'w', 'j', 'x', 'C'],
      0: ['D', 'v', 'J', 'P', 'g', 'h'],
    },
  },
}

export default cypher

// React
import { createRoot } from 'react-dom/client'
import App from './App'
import reportWebVitals from './reportWebVitals'
import PropTypes from 'prop-types'

// Redux
import { Provider } from 'react-redux'

import { QueryClientProvider, QueryClient, QueryCache } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'

// URL
import RedirectURL from './security/RedirectURL'

// Dependencies
import 'core-js'

import { ErrorBoundary } from 'react-error-boundary'
import { CButton } from '@coreui/react-pro'
import cache from './utils/data/cache'

import { createInsiteErrors } from './utils/functions/createInsiteErrors'
import { reduxStore } from './redux/reduxTool'

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
  queryCache: new QueryCache({
    onError: (error) => {
      console.error(error.message, '- Top Level Catch')
    },
  }),
})

const container = document.getElementById('root')

const root = createRoot(container)

function ErrorWrapper({ children }) {
  return (
    <ErrorBoundary
      fallbackRender={({ error, resetErrorBoundary }) => {
        createInsiteErrors(error)
        return (
          <div role="alert">
            <div>
              {console.log(error.name, error, error.componentStack, error.page)}
              <div className="d-flex justify-content-center align-items-center">
                <img
                  src="https://cdn.dribbble.com/users/4215/screenshots/2291960/media/5a3eb9b5dc4caccf8d94ad753e5f3273.gif"
                  alt="Oops something went wrong..."
                  className="catcutout"
                  style={{ width: '100%', objectFit: 'contain' }}
                />
              </div>
              <div id="text-and-button" className="d-flex flex-column">
                <div className="p-3">
                  <pre style={{ whiteSpace: 'normal' }}>Error Reason: {error.message}</pre>
                  <code>{error.stack}</code>
                </div>
                <div className="d-flex justify-content-center">
                  <div className="p-2">
                    <CButton
                      onClick={() => {
                        resetErrorBoundary()
                        queryClient.clear()
                        cache.clearAll()
                        window.location.href = '#/overview'
                        window.location.reload()
                      }}
                      style={{ maxWidth: '8rem', alignSelf: 'center' }}
                    >
                      Try again
                    </CButton>
                  </div>

                  <div className="p-2">
                    <CButton
                      onClick={() => {
                        resetErrorBoundary()
                        queryClient.clear()
                        cache.clearAll()
                        sessionStorage.clear()
                        localStorage.clear()
                        window.location.href = '#/overview'
                        window.location.reload()
                      }}
                      style={{ maxWidth: '8rem', alignSelf: 'center' }}
                    >
                      Log out
                    </CButton>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )
      }}
    >
      {children}
    </ErrorBoundary>
  )
}

ErrorWrapper.propTypes = {
  children: PropTypes.node.isRequired,
}
console.log(reduxStore,'reduxstore')
root.render(
  <Provider store={reduxStore}>
    <RedirectURL>
      <ErrorWrapper>
        <QueryClientProvider client={queryClient}>
          <App />
          <ReactQueryDevtools initialIsOpen={false} />
        </QueryClientProvider>
      </ErrorWrapper>
    </RedirectURL>
  </Provider>,
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()

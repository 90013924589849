import cypher from './cypher'

const encoder = {
  encode: (value) => {
    if (!value) {
      
      return value
    }

    const strategy = cypher.strategies[Math.floor(Math.random() * cypher.strategies?.length)]
    const split = value.split('')
    let encoded = ''
    split.forEach((str) => {
      encoded +=
        cypher.cypher[strategy][str][
          Math.floor(Math.random() * cypher.cypher[strategy][str].length)
        ]
    })
    let hash = ''
    for (let i = 0; i < 10; i++) {
      hash += cypher.hash[Math.floor(Math.random() * cypher.hash?.length)]
    }
    return `${encoded}+${strategy}-${hash}`
  },
  decode: (value) => {
    if (!value) {
      return value
    }

    

    const strategy = value?.split('+')[1]?.split('-')[0]
    const split = value?.split('+')[0]?.split('')
    let decoded = ''
 
    split?.forEach((str) => {
      if(cypher?.cypher?.[strategy])
      for (const [key, value] of Object?.entries(cypher?.cypher?.[strategy])) {
        if (value?.includes(str)) {
          decoded += key
        }
      }
    })
    return decoded
  },
}

export default encoder

// React
import { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

// Google API
import Geocode from 'react-geocode'
import { GoogleMap, Marker } from '@react-google-maps/api'

const MarkerList = ({ locations }) => {
  return locations.map((location, index) => {
    return <MarkerWithInfoWindow key={index.toString()} location={location} index={index} />
  })
}

const MarkerWithInfoWindow = ({ location }) => {
  const rankColors = {
    0: '#a3a3a3',
    1: '#1e9319',
    2: '#51a720',
    3: '#85b824',
    4: '#a7c829',
    5: '#d6cb2c',
    6: '#dac52e',
    7: '#dfc030',
    8: '#e3ba30',
    9: '#e8b532',
    10: '#ecae33',
    11: '#f0a834',
    12: '#f5a336',
    13: '#f19536',
    14: '#ed8735',
    15: '#ea7a36',
    16: '#e56b35',
    17: '#e15d35',
    18: '#dd4f34',
    19: '#d94234',
    20: '#d84239',
    X: '#d73b34',
  }

  return (
    <Marker
      position={{
        lat: location?.lat,
        lng: location?.lng,
      }}
      label={{
        text: location.position.toString(),
        color: 'white',
      }}
      icon={{
        path: window.google.maps.SymbolPath.CIRCLE,
        scale: 15,
        fillColor: rankColors[location.position] || rankColors.X,
        fillOpacity: 1,
        strokeColor: 'white',
        strokeWeight: 1,
      }}
    ></Marker>
  )
}

MarkerWithInfoWindow.propTypes = {
  location: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  setSelectedLocation: PropTypes.func.isRequired,
}

function CompetitorSpread({ location }) {
  const [center, setCenter] = useState({ lat: 0, lng: 0 })
  const [zoom, setZoom] = useState(12)
  console.log(location)
  // Authentication
  const apiKey = 'AIzaSyBqs2R46BBuA6zI9VrzWyNA-9xbB0w2qFQ'
  Geocode.setApiKey(apiKey)

  // Google Maps Component
  // const { isLoaded } = useJsApiLoader({
  //   id: 'google-map-script',
  //   googleMapsApiKey: apiKey,
  // })

  const calculateCenter = () => {
    if (location?.data?.length > 0) {
      let avgLat = 0
      let avgLng = 0
      let adjust = 0
      location.data.forEach((point) => {
        if (!point?.lat || !point?.lng) adjust++
        avgLat += point?.lat || 0
        avgLng += point?.lng || 0
      })
      avgLat = avgLat / (location?.data?.length - adjust)
      avgLng = avgLng / (location?.data?.length - adjust)
      return { lat: avgLat, lng: avgLng }
    }
    return { lat: 0, lng: 0 }
  }

  useEffect(() => {
    setCenter(calculateCenter())
  }, [location])

  return (
    <div>
      {location ? (
        <GoogleMap mapContainerStyle={{ height: '700px' }} center={center} zoom={zoom}>
          {<MarkerList locations={location?.data} />}
          <></>
        </GoogleMap>
      ) : (
        <div className="mb-2" style={{ fontSize: '20px', fontWeight: 'bold' }}>
          Select a point to get local rank competitor data
        </div>
      )}
    </div>
  )
}

CompetitorSpread.propTypes = {
  location: PropTypes.object.isRequired,
}

export default CompetitorSpread

import { generateLocations } from './generateLocations'

export const getGridPreview = (lat, lng, radius, grid) => {
  const locations = generateLocations(lat, lng, radius, grid)
  locations.forEach((location) => {
    location.label = { text: '', color: 'transparent' }
    location.icon = { fillColor: 'transparent', strokeColor: 'black' }
  })
  return locations
}
export const getGridMiniPreview = (lat, lng, radius, grid) => {
  const locations = generateLocations(lat, lng, radius, grid)
  let locationObjects = {}
  let latobj = {}
  let arrayLocations = []
  locations.forEach((location, index) => {
    locationObjects = { ...locationObjects, [`${location?.lat} , ${location?.lng}`]: index }
    latobj = { ...latobj, [location?.lng]: index }
    arrayLocations.push([location?.lat, location?.lng])
  })
  //.log(locationObjects)
  return { location: locationObjects, latcheck: latobj, check: arrayLocations }
}

import { useEffect } from 'react';
import PropTypes from 'prop-types'

function RedirectURL({ children }) {
  useEffect(() => {
    if (window.location.origin === 'https://www.omginsite.com') {
      window.location.href = `https://omginsite.com/${window.location.hash}`
    }
  }, [])

  return <>{children}</>
}

RedirectURL.propTypes = {
  children: PropTypes.object,
}

export default RedirectURL

// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app'
import { getAnalytics } from 'firebase/analytics'
import { getAuth } from 'firebase/auth'
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseGoogleConfig = {
  apiKey: 'AIzaSyAj7NpA8CbMGEgSayKu2Tryu2LUf90dpTY',
  authDomain: 'omginsite-gapi.firebaseapp.com',
  projectId: 'omginsite-gapi',
  storageBucket: 'omginsite-gapi.appspot.com',
  messagingSenderId: '404623669783',
  appId: '1:404623669783:web:ce229f3611beaa42f37a52',
  measurementId: 'G-36KZQG5F2X',
}

// Initialize Firebase
const googleApp = initializeApp(firebaseGoogleConfig, 'google')
const analytics = getAnalytics(googleApp)

export const googleAuth = getAuth(googleApp)

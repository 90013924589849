// React
import { lazy, Suspense } from 'react'
import { HashRouter, Route, Routes } from 'react-router-dom'
// Redux
import { useSelector } from 'react-redux'

// Context
import GoogleAuthContextProvider from './context/GoogleAuthContext'
import FacebookAuthContextProvider from './context/FacebookAuthContext'

// CoreUI
import { CSpinner } from '@coreui/react-pro'

// Security
import ProtectedRoute from './security/ProtectedRoute'
import DefaultHandler from './security/DefaultHandler'

// Routes
import routes from './routes'

// Style
import './scss/loading.scss'
import './scss/style.scss'
import LocalRankRender from './apps/local-rank/LocalRankRender'
import LocalRankLive from './views/localrank/live/LocalRankLive'

// Containers
const DashboardLayout = lazy(() => import('./layout/DashboardLayout'))
// Pages
const Status = lazy(() => import('./pages/status/Status'))
const Debug = lazy(() => import('./pages/debug/Debug'))
const Login = lazy(() => import('./pages/login/Login'))
const Page404 = lazy(() => import('./pages/404/Page404'))
const ProductSupport = lazy(() => import('./pages/support/ProductSupport'))

//Screen Size Queries Global variables
window.sm = 576
window.md = 768
window.lg = 992
window.xl = 1200
window.xxl = 1400

const localRankWidgetRoute = routes.find((route) => route.path === '/local-rank-widget')

function App() {
  const devOnly = window.location.origin.includes('dev.omginsite.com')
  return (
    <HashRouter>
      <GoogleAuthContextProvider>
        <FacebookAuthContextProvider>
          <Suspense
            fallback={
              <div className="loading-container-full">
                <div className="lcf-logo">
                  <img className="omg-logo" src="./assets/OMG-2020.png" alt="OMG Logo"></img>
                </div>
                <div className="lcf-anim">
                  <CSpinner color="primary" />
                </div>
              </div>
            }
          >
            <Routes>
              <Route
                path="/"
                name="Home"
                element={
                  <ProtectedRoute
                    component={() => (
                      <Suspense fallback="">
                        <DashboardLayout />
                      </Suspense>
                    )}
                  />
                }
              >
                <Route index element={<DefaultHandler />} />
                {routes.map((route) => {
                  return (
                    route.component && (
                      <Route
                        key={route.name}
                        path={route.path}
                        exact={route.exact}
                        name={route.name}
                        element={
                          <Suspense
                            key={route.name}
                            fallback={
                              <div
                                className="d-flex align-items-center justify-content-center"
                                style={{ height: '25vh' }}
                              >
                                <CSpinner color="primary" />
                              </div>
                            }
                          >
                            <route.component />
                          </Suspense>
                        }
                      />
                    )
                  )
                })}
              </Route>
              <Route
                exact
                path="/products/:product_name/:product_id"
                element={
                  <Suspense fallback={<CSpinner color="primary" />}>
                    <ProductSupport />
                  </Suspense>
                }
              />
              <Route
                path="/status"
                element={
                  <Suspense fallback={<CSpinner color="primary" />}>
                    <Status />
                  </Suspense>
                }
              />
              <Route
                path="/debug"
                element={
                  <ProtectedRoute
                    component={() => (
                      <Suspense fallback={<CSpinner color="primary" />}>{Debug}</Suspense>
                    )}
                  />
                }
              />
              <Route
                path="/login"
                element={
                  <Suspense fallback={<CSpinner color="primary" />}>
                    <Login />
                  </Suspense>
                }
              />
              {!devOnly && (
                <Route
                  path="*"
                  element={
                    <Suspense fallback={<CSpinner color="primary" />}>
                      <Page404 />
                    </Suspense>
                  }
                />
              )}
            </Routes>
          </Suspense>
        </FacebookAuthContextProvider>
      </GoogleAuthContextProvider>
      {devOnly && (
        <Routes>
          <Route
            path="/local-rank-widget"
            element={
              <Suspense fallback={<CSpinner color="primary" />}>
                {/* {localRankWidgetRoute.component} */}
                <div>
                  <LocalRankLive />
                </div>
              </Suspense>
            }
          />
        </Routes>
      )}
    </HashRouter>
  )
}

export default App

import { lazy } from 'react'
const isDevelopment = window.location.origin.includes('dev.omginsite.com') ||window.location.origin.includes('localhost:5173') 

// Admin
const LSAVerticals = lazy(() => import('./views/admin/lsa/LSAVerticals'))

// Employee
const EmployeeHome = lazy(() => import('./views/employee/overview/EmployeeOverview'))
const CustomRanks = lazy(() => import('./views/employee/ranks/custom/CustomLocalRanks'))
const CreatedRanks = lazy(() => import('./views/employee/ranks/created/CreatedCustomRanks'))
const ViewCustom = lazy(() => import('./views/employee/ranks/view/ViewCustomGrid'))
const TopLSAClientRanks = lazy(() => import('./views/employee/overview/sales/TopLSAOverview'))
const TopGBPClientRanks = lazy(() => import('./views/employee/overview/sales/TopGBPOverview'))
const TimelineSearch = lazy(() => import('./views/employee/timelines/TimelineSearch'))
const GoogleAdsEstimator = lazy(() =>
  import('./views/employee/adsestimator/googleads/GoogleAdsEstimator'),
)
const LSAEstimator = lazy(() => import('./views/employee/adsestimator/lsa/LSAEstimator'))
const FacebookAdsEstimator = lazy(() =>
  import('./views/employee/adsestimator/facebookads/FacebookAdsEstimator'),
)
const EmployeeStatus = lazy(() => import('./views/employee/status/EmployeeStatus'))

// Overview
const Overview = lazy(() => import('./views/overview/Overview'))
const FilteredView = lazy(() => import('./views/overview/FilteredView'))
const Profile = lazy(() => import('./views/profile/Profile'))

// Activity
const Activity = lazy(() => import('./views/activity/Activity'))
const MyAlerts = lazy(() => import('./views/activity/MyAlerts'))
const PerformanceReport = lazy(() => import('./views/performancev2/report/PerformanceReport'))
const PerformanceClone = lazy(() => import('./views/performancev2/report/PerformanceClone'))
const FilterTesting = lazy(() => import('./views/performancev2/report/FilterTesting'))
const LocalRankLive = lazy(() => import('./views/localrank/live/LocalRankLive'))
const LocalRankHistory = lazy(() => import('./views/localrank/history/LocalRankHistory'))
const LocalRankView = lazy(() => import('./views/localrank/view/LocalRankView'))
const LocalRankTimelines = lazy(() => import('./views/localrank/timelines/LocalRankTimelines'))
const Reviews = lazy(() => import('./views/products/reviews/Reviews'))
const UniSocial = lazy(() => import('./views/social/UniSocial'))
const Billing = lazy(() => import('./views/billing/Billing'))
const Listings = lazy(() => import('./views/products/listings/Listings'))
const ListingsV2 = lazy(()=> import('./views/products/listings/ListingsV2'))
const ListingSalesTool = lazy(()=> import('./views/products/listings/ListingSalesTool'))
const GooglePhotos = lazy(() => import('./views/products/googlephotos/GooglePhotos'))
const GoogleQA = lazy(() => import('./views/products/googleqa/GoogleQA'))
const SocialCalendarPage = lazy(() => import('./views/social/SocialCalendarPage'))
// const LocalRankAlerts = lazy(() => import('./views/localrankv2/notifications/LocalRankAlerts'))
// const LocalRankGenerator = lazy(() => import('./views/localrankv2/generation/LocalRankGenerator'))
// const LocalRankResults = lazy(() => import('./views/localrankv2/gridview/LocalRankResults'))

// Products
const Products = lazy(() => import('./views/products/viewallproducts/Products'))
const GoogleBusinessPro = lazy(() => import('./views/products/gbp/GoogleBusinessPro'))
const GoogleCallsAdvisor = lazy(() => import('./views/products/gca/GoogleCallsAdvisor'))
const LocalMarketingEssentials = lazy(() => import('./views/products/lme/LocalMarketingEssentials'))
const FacebookPro = lazy(() => import('./views/products/fbp/FacebookPro'))
const ManagedBusinessListings = lazy(() => import('./views/products/mbl/ManagedBusinessListings'))
const BusinessListings = lazy(() => import('./views/products/bl/BusinessListings'))
const Seo = lazy(() => import('./views/products/seo/Seo'))
const GoogleAds = lazy(() => import('./views/products/googleads/GoogleAds'))
const AdsXpress = lazy(() => import('./views/products/googleads/AdsXpress'))
const GoogleAdsCampaign = lazy(() => import('./views/products/googleadscampaign/GoogleAdsCampaign'))
const AdsXpressCampaign = lazy(() => import('./views/products/googleadscampaign/AdsXpressCampaign'))
const LocalServicesAds = lazy(() => import('./views/products/lsa/LocalServicesAds'))
const OnHold = lazy(() => import('./views/products/oh/OnHold'))
const AdSpec = lazy(() => import('./views/products/adspec/AdSpec'))
const InsiteBlogs = lazy(() => import('./views/products/insiteblogs/InsiteBlogs'))
const OnHoldPackages = lazy(() => import('./views/products/oh/OnHoldPackages'))
const FacebookAds = lazy(() => import('./views/products/fba/FacebookAds'))
const ProductSupport = lazy(() => import('./pages/support/ProductSupport'))
const Webworx = lazy(() => import('./views/products/wx/Webworx'))
const WebHosting = lazy(() => import('./views/products/whost/WebHosting'))
const WebsiteProduct = lazy(() => import('./views/products/websiteproduct/WebsiteProduct'))
const Powerpage = lazy(() => import('./views/products/powerpg/Powerpage'))
const LeadsCenter = lazy(() => import('./views/products/leadscenter/LeadsCenter'))
const ROICenter = lazy(() => import('./views/products/roicenter/ROICenter'))
const QRCodePage = lazy(() => import('./views/products/qrmain/QRCodePage'))
const ReviewSolicitation = lazy(() => import('./views/reviewsolicitation/ReviewSolicitation'))
const ConstantContact = lazy(() => import('./views/products/constantcontact/ConstantContact'))

// Debugging
const LiveBugReports = lazy(() => import('./views/employee/livebugreports/LiveBugReports'))
//Testing Components
const TestPage = lazy(() => import('./views/products/test/TestPage'))
const FacebookAdsDev = lazy(() => import('./views/products/fba/FacebookAdsDev'))

const routes = [
  // Administation
  {
    path: '/admin/lsa-verticals',
    name: 'LSA Verticals',
    component: LSAVerticals,
    exact: true,
  },

  // Employee Management
  {
    path: '/employee',
    name: 'Employee Home',
    component: EmployeeHome,
    exact: true,
  },
  {
    path: '/employee/custom-rank/run',
    name: 'Custom Rank',
    component: CustomRanks,
    exact: true,
  },
  {
    path: '/employee/custom-rank/created',
    name: 'Created Custom Ranks',
    component: CreatedRanks,
    exact: true,
  },
  {
    path: '/employee/custom-rank/view/:id',
    name: 'Created Custom Ranks',
    component: ViewCustom,
    exact: true,
  },
  {
    path: '/employee/top-lsa-clients',
    name: 'Top LSA Client Ranks',
    component: TopLSAClientRanks,
    exact: true,
  },
  {
    path: '/employee/top-gbp-clients',
    name: 'Top GBP Client Ranks',
    component: TopGBPClientRanks,
    exact: true,
  },
  {
    path: '/employee/timeline/search',
    name: 'Timeline Search',
    component: TimelineSearch,
    exact: true,
  },
  {
    path: '/employee/ads-estimator/google-ads',
    name: 'Google Ads Estimator',
    component: GoogleAdsEstimator,
    exact: true,
  },
  {
    path: '/employee/ads-estimator/lsa',
    name: 'LSA Estimator',
    component: LSAEstimator,
    exact: true,
  },
  {
    path: '/employee/status',
    name: 'Employee Status',
    component: EmployeeStatus,
    exact: true,
  },

  // Overview
  { path: '/', exact: true, name: 'Home' },
  { path: '/overview', name: 'Overview', component: Overview, exact: true },
  { path: '/overview/:company_id', name: 'Company', component: FilteredView, exact: true },

  // Activity
  { path: '/activity', name: 'Activity', component: Activity },

  //My Alerts
  { path: '/my-alerts', name: 'My Alerts', component: MyAlerts },

  // Performance
  { path: '/performance-report', name: 'Performance Report', component: PerformanceReport },
  { path: '/performance-report/clone', name: 'Performance Report', component: PerformanceClone },
  { path: '/performance-report/test', name: 'Performance Report', component: FilterTesting },

  //Leads Center Page
  {
    path: '/leads-center',
    name: 'Leads Center',
    component: LeadsCenter,
    exact: true,
  },
  //Call Data Page
  {
    path: '/roi-center',
    name: 'ROI Center',
    component: ROICenter,
    exact: true,
  },
  // Local Rank
  { path: '/local-rank-grid/live', name: 'Local Rank', component: LocalRankLive },
  { path: '/local-rank-grid/history', name: 'Local Rank', component: LocalRankHistory },
  { path: '/local-rank-grid/view/:id', name: 'Local Rank', component: LocalRankView },
  { path: '/local-rank-grid/timelines', name: 'Local Rank', component: LocalRankTimelines },

  //Review Specific Post Link
  {
    path: '/products/:product_name/reviews/:product_id/:review_id',
    name: 'Reviews Selected Review',
    component: Reviews,
    exact: true,
  },

  //Review Specific Post Link
  {
    path: '/products/reviews/',
    name: 'All Reviews',
    component: Reviews,
    exact: true,
  },

  //All Reviews - No Specific Product (from Performance Report)
  {
    path: '/products/local-marketing-essentials/posts/:product_id/:post_id',
    name: 'UniSocial Selected Post',
    component: UniSocial,
    exact: true,
  },

  //UniSocial Specific Post Link
  {
    path: '/products/posts/',
    name: 'All Posts',
    component: UniSocial,
    exact: true,
  },

  // Business Profile
  { path: '/profile/:company_id', name: 'Profile', component: Profile },

  // All Products
  { path: '/products', name: 'Products', component: Products, exact: true },

  // GBP
  {
    path: '/products/google-business-pro/:product_id',
    name: 'Google Business Pro',
    component: GoogleBusinessPro,
    exact: true,
  },
  {
    path: '/products/google-business-pro/qa/:product_id',
    name: 'Q&A',
    component: GoogleQA,
    exact: true,
  },
  {
    path: '/products/google-business-pro/reviews/:product_id',
    name: 'Reviews',
    component: Reviews,
    exact: true,
  },
  {
    path: '/products/google-business-pro/listings/:product_id',
    name: 'Listings',
    component: Listings,
    exact: true,
  },
  {
    path: '/products/google-business-pro/posts/:product_id',
    name: 'UniSocial',
    component: UniSocial,
    exact: true,
  },
  {
    path: '/products/google-business-pro/photos/:product_id',
    name: 'Google Photos',
    component: GooglePhotos,
    exact: true,
  },

  //LME
  {
    path: '/products/local-marketing-essentials/:product_id',
    name: 'Local Marketing Essentials',
    component: LocalMarketingEssentials,
    exact: true,
  },
  {
    path: '/products/local-marketing-essentials/qa/:product_id',
    name: 'Q&A',
    component: GoogleQA,
    exact: true,
  },
  {
    path: '/products/local-marketing-essentials/reviews/:product_id',
    name: 'Reviews',
    component: Reviews,
    exact: true,
  },
  {
    path: '/products/local-marketing-essentials/listings/:product_id',
    name: 'Listings',
    component: Listings,
    exact: true,
  },
  {
    path: '/products/local-marketing-essentials/posts/:product_id',
    name: 'UniSocial',
    component: UniSocial,
    exact: true,
  },
  {
    path: '/products/local-marketing-essentials/photos/:product_id',
    name: 'Google Photos',
    component: GooglePhotos,
    exact: true,
  },

  // TME
  {
    path: '/products/tow-marketing-essentials/:product_id',
    name: 'Tow Marketing Essentials',
    component: LocalMarketingEssentials,
    exact: true,
  },
  {
    path: '/products/tow-marketing-essentials/qa/:product_id',
    name: 'Q&A',
    component: GoogleQA,
    exact: true,
  },
  {
    path: '/products/tow-marketing-essentials/reviews/:product_id',
    name: 'Reviews',
    component: Reviews,
    exact: true,
  },
  {
    path: '/products/tow-marketing-essentials/listings/:product_id',
    name: 'Listings',
    component: Listings,
    exact: true,
  },
  {
    path: '/products/tow-marketing-essentials/posts/:product_id',
    name: 'UniSocial',
    component: UniSocial,
    exact: true,
  },
  {
    path: '/products/tow-marketing-essentials/photos/:product_id',
    name: 'Google Photos',
    component: GooglePhotos,
    exact: true,
  },

  // MBL
  {
    path: '/products/managed-business-listings/:product_id',
    name: 'MBL',
    component: ManagedBusinessListings,
    exact: true,
  },
  {
    path: '/products/managed-business-listings/listings/:product_id',
    name: 'Listings',
    component: Listings,
    exact: true,
  },
  {
    path: '/products/managed-business-listings/photos/:product_id',
    name: 'Google Photos',
    component: GooglePhotos,
    exact: true,
  },

  //BL
  {
    path: '/products/business-listings/:product_id',
    name: 'bl',
    component: BusinessListings,
    exact: true,
  },
  {
    path: '/products/business-listings/listings/:product_id',
    name: 'Listings',
    component: Listings,
    exact: true,
  },
  {
    path: '/products/business-listings/photos/:product_id',
    name: 'Google Photos',
    component: GooglePhotos,
    exact: true,
  },

  // FBP
  {
    path: '/products/facebook-pro/:product_id',
    name: 'Facebook Pro',
    component: FacebookPro,
    exact: true,
  },
  {
    path: '/products/facebook-pro/reviews/:product_id',
    name: 'Reviews',
    component: Reviews,
    exact: true,
  },
  {
    path: '/products/facebook-pro/posts/:product_id',
    name: 'UniSocial',
    component: UniSocial,
    exact: true,
  },

  // Google Ads
  {
    path: '/products/google-ads/:product_id',
    name: 'Google Ads',
    component: GoogleAds,
    exact: true,
  },
  {
    path: '/products/google-ads/all-ads/:product_id',
    name: 'Google Ads',
    component: GoogleAdsCampaign,
    exact: true,
  },

  // Ads Xpress
  {
    path: '/products/ads-xpress/:product_id',
    name: 'Ads Xpress',
    component: AdsXpress,
    exact: true,
  },
  {
    path: '/products/ads-xpress/all-ads/:product_id',
    name: 'Ads Xpress',
    component: AdsXpressCampaign,
    exact: true,
  },

  // Facebook Ads
  {
    path: '/products/facebook-ads/:product_id',
    name: 'Facebook Ads',
    component: FacebookAds,
    exact: true,
  },

  // GCA
  {
    path: '/products/google-calls-advisor/:product_id/',
    name: 'Google Calls Advisor',
    component: GoogleCallsAdvisor,
  },

  {
    path: '/products/google-calls-advisor/qa/:product_id',
    name: 'Q&A',
    component: GoogleQA,
    exact: true,
  },
  {
    path: '/products/google-calls-advisor/reviews/:product_id',
    name: 'Reviews',
    component: Reviews,
    exact: true,
  },
  {
    path: '/products/google-calls-advisor/listings/:product_id',
    name: 'Listings',
    component: Listings,
    exact: true,
  },
  {
    path: '/products/google-calls-advisor/posts/:product_id',
    name: 'UniSocial',
    component: UniSocial,
    exact: true,
  },
  {
    path: '/products/google-calls-advisor/photos/:product_id',
    name: 'Google Photos',
    component: GooglePhotos,
    exact: true,
  },

  // LSA
  {
    path: '/products/local-services-ads/:product_id/',
    name: 'Local Services Ads',
    component: LocalServicesAds,
  },

  // WebWorx
  {
    path: '/products/webworx-professional/:product_id',
    name: 'Webworx',
    component: Webworx,
    exact: true,
  },

  // WebHosting
  {
    path: '/products/website-hosting/:product_id',
    name: 'Website Hosting',
    component: WebHosting,
    exact: true,
  },

  // Powerpage
  {
    path: '/products/powerpage/:product_id',
    name: 'Powerpage',
    component: Powerpage,
    exact: true,
  },

  // Website Product
  {
    path: '/products/website/:product_id',
    name: 'Website',
    component: WebsiteProduct,
    exact: true,
  },

  // OMGinContact
  {
    path: '/products/omgincontact/:product_id',
    name: 'OMGinContact',
    component: ConstantContact,
    exact: true,
  },

  // Ad Spec
  {
    path: '/products/ad-spec',
    name: 'Ad Spec',
    component: AdSpec,
    exact: true,
  },

  // QR Codes
  {
    path: '/products/:product_name/qr-codes/:product_id',
    name: 'QR Codes',
    component: QRCodePage,
  },

  // Review Solicitation
  {
    path: '/review-solicitation/:product_id',
    name: 'Review Solicitation',
    component: ReviewSolicitation,
    exact: true,
  },
  {
    path: '/review-solicitation/:product_type/:product_id',
    name: 'Review Solicitation',
    component: ReviewSolicitation,
    exact: true,
  },

  //Billing
  { path: '/billing', name: 'Billing', component: Billing, exact: true },

  // Product Support
  {
    path: '/products/:product_name/:product_id',
    name: 'Product Support',
    component: ProductSupport,
  },

  // Live Bug Reports
  {
    path: '/live-bug-reports',
    name: 'Live Bug Reports',
    component: LiveBugReports,
  },
]

const developmentRoutes = [
  //listingsv2
  {
    path: '/products/listings',
    name: 'Listings',
    component: ListingsV2,
    exact: true,
  },
    //listing sales tool
    {
      path: '/products/listing-sales',
      name: 'Listings Sales Tool',
      component: ListingSalesTool,
      exact: true,
    },
  // Fb Ads
  {
    path: '/products/facebook-ads-dev/:product_id',
    name: 'Facebook Ads',
    component: FacebookAdsDev,
    exact: true,
  },
  // Ads Estimator
  {
    path: '/employee/ads-estimator/facebook-ads',
    name: 'Facebook Ads Estimator',
    component: FacebookAdsEstimator,
    exact: true,
  },

  //SEO
  {
    path: '/products/seo',
    name: 'seo',
    component: Seo,
    exact: true,
  },

  //Insite Blogs
  {
    path: '/insite-blogs',
    name: 'Insite Blogs',
    component: InsiteBlogs,
    exact: true,
  },

  // Local Rank V2
  // {
  //   path: '/local-rank-v2/notifications',
  //   name: 'Local Rank Alerts',
  //   component: LocalRankAlerts,
  //   exact: true,
  // },
  // {
  //   path: '/local-rank-v2/generate',
  //   name: 'Local Rank Generator',
  //   component: LocalRankGenerator,
  //   exact: true,
  // },

  //On-Hold
  {
    path: '/products/on-hold',
    name: 'On-Hold',
    component: OnHold,
    exact: true,
  },

  //On-Hold Packages
  {
    path: '/products/on-hold-packages',
    name: 'On-Hold-Packages',
    component: OnHoldPackages,
    exact: true,
  },

  // Social calendar
  {
    path: '/products/social-calendar',
    name: 'Social Calendar',
    component: SocialCalendarPage,
    exact: true,
  },

  // Test Endpoint
  {
    path: '/test',
    name: 'Test',
    component: TestPage,
    exact: true,
  },
]

const allowedRoutes = isDevelopment ? [...routes, ...developmentRoutes] : routes

export default allowedRoutes

// React
import { useState, useEffect } from 'react';
import PropTypes from 'prop-types'

// CoreUI
import { CRow, CCol, CProgress, CProgressBar } from '@coreui/react-pro'

// Style
import '../../scss/_local_rank.scss'

function PercentageChart({ locations }) {
  const rankColors = {
    0: '#a3a3a3',
    1: '#1e9319',
    2: '#51a720',
    3: '#85b824',
    4: '#a7c829',
    5: '#d6cb2c',
    6: '#dac52e',
    7: '#dfc030',
    8: '#e3ba30',
    9: '#e8b532',
    10: '#ecae33',
    11: '#f0a834',
    12: '#f5a336',
    13: '#f19536',
    14: '#ed8735',
    15: '#ea7a36',
    16: '#e56b35',
    17: '#e15d35',
    18: '#dd4f34',
    19: '#d94234',
    20: '#d84239',
    X: '#d73b34',
  }

  const [highRanking, setHighRanking] = useState(0)
  const [highRankingPercentage, setHighRankingPercentage] = useState(0)
  const [midRanking, setMidRanking] = useState(0)
  const [midRankingPercentage, setMidRankingPercentage] = useState(0)
  const [lowRanking, setLowRanking] = useState(0)
  const [lowRankingPercentage, setLowRankingPercentage] = useState(0)
  const [averageRating, setAverageRating] = useState(0)

  useEffect(() => {
    let count = 0
    let total = 0
    let high = 0
    let mid = 0
    let low = 0

    locations?.forEach((location) => {
      if (location.rank > 0 && location.rank <= 4) {
        high++
        total += location.rank
        count++
      }
      if (location.rank > 4 && location.rank <= 15) {
        mid++
        total += location.rank
        count++
      }
      if (location.rank > 15) {
        low++
        total += location.rank
        count++
      }
    })

    setHighRanking(high)
    setHighRankingPercentage((high / count) * 100)
    setMidRanking(mid)
    setMidRankingPercentage((mid / count) * 100)
    setLowRanking(low)
    setLowRankingPercentage((low / count) * 100)
    setAverageRating((total / count).toFixed(1))
  }, [locations])

  return (
    <div>
      <CRow className="mb-4">
        <CCol md={3}>
          <div
            className="d-flex flex-column justify-content-center align-items-center"
            style={{
              width: '100%',
              height: '100%',
              backgroundColor: `${rankColors?.[parseInt(averageRating).toFixed(0)] || rankColors['X']
                }`,
              color: 'white',
            }}
          >
            <div className="mb-2" style={{ fontSize: '20px', fontWeight: 'bold' }}>
              {parseFloat(averageRating) >= 21 ? '21+' : averageRating}
            </div>
            <div>Average Map Rating</div>
          </div>
        </CCol>
        <CCol md={9}>
          <CRow className="mb-2">
            <CCol md={2}>
              <span style={{ fontWeight: 'bold' }}>High Ranking</span>
            </CCol>
            <CCol md={9}>
              <CProgress className="local-rank-bar" height={20}>
                <CProgressBar color="success" value={highRankingPercentage}></CProgressBar>
              </CProgress>
            </CCol>
            <CCol md={1}>
              <span style={{ fontWeight: 'bold', color: '#2eb85c' }}>{highRanking}</span>
            </CCol>
          </CRow>
          <CRow className="mb-2">
            <CCol md={2}>
              <span style={{ fontWeight: 'bold' }}>Mid Ranking</span>
            </CCol>
            <CCol md={9}>
              <CProgress className="local-rank-bar" height={20}>
                <CProgressBar color="warning" value={midRankingPercentage}></CProgressBar>
              </CProgress>
            </CCol>
            <CCol md={1}>
              <span style={{ fontWeight: 'bold', color: '#f9b115' }}>{midRanking}</span>
            </CCol>
          </CRow>
          <CRow className="mb-2">
            <CCol md={2}>
              <span style={{ fontWeight: 'bold' }}>Low Ranking</span>
            </CCol>
            <CCol md={9}>
              <CProgress className="local-rank-bar" height={20}>
                <CProgressBar color="danger" value={lowRankingPercentage}></CProgressBar>
              </CProgress>
            </CCol>
            <CCol md={1}>
              <span style={{ fontWeight: 'bold', color: '#e55353' }}>{lowRanking}</span>
            </CCol>
          </CRow>
        </CCol>
      </CRow>
    </div>
  )
}

PercentageChart.propTypes = {
  locations: PropTypes.array.isRequired,
}

export default PercentageChart

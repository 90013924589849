import decrypt from './decrypt'

export default {
  authenticate: function () {
    const token = decrypt()
    if (!token) {
      return false
    }
    if (!token?.exp) {
      localStorage.clear()
      return false
    }
    if (Date.now() <= token.exp) {
      return true
    } else {
      localStorage.clear()
      return false
    }
  },
}

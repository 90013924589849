import { Navigate } from 'react-router-dom'
import { useClientInfo } from '../utils/reduxHookSignals/clientInformation'

function DefaultHandler() {
  const { clientStartingPoint } = useClientInfo()
  console.log(clientStartingPoint.value)
  return clientStartingPoint.value?.perm && clientStartingPoint.value?.clients?.length > 0 ? (
    <Navigate to="/employee" />
  ) : clientStartingPoint.value?.perm ? (
    <Navigate to="/employee/custom-rank/run" />
  ) : (
    <Navigate to="/overview" />
  )
}

export default DefaultHandler

const changeDate = (inputDate, options = {}, timeReturned = false, onlyDateConstruct = false) => {
  //UNI data can be given as 'mm-dd-yy ___ Time' which Safari can not register properly so manually need to make slashes from dashes
  let splitDate = []
  let dateAndTimeFull = ''

  if (options?.mongoDate) {
    const date = inputDate.split('T')[0].split('-')
    const time = inputDate.split('T')[1].split(':')
    const dateTime = new Date(`${date[1]}/${date[2]}/${date[0]} ${time[0]}:${time[1]}`)
    return `${dateTime.toLocaleDateString()} ${dateTime.toLocaleTimeString([], {
      hour: '2-digit',
      minute: '2-digit',
    })}`
  }

  if (typeof inputDate === 'string') {
    splitDate = inputDate.split(' ')
    const dateEdit = splitDate[0].replace(/-/g, '/')
    dateAndTimeFull = `${dateEdit} ${splitDate[1] ? splitDate[1] : ''}` //second space checks to see if a time is included or not
  } else {
    dateAndTimeFull = inputDate
  }
  const createDate = new Date(dateAndTimeFull)
  if (onlyDateConstruct) {
    return createDate
  }
  const outputDate = createDate.toLocaleDateString('default', options)

  if (timeReturned) {
    let outputTime = createDate.toLocaleTimeString('default', options)

    const editedTime = outputTime.split(/[:\s]/)

    outputTime = `${editedTime[0]||''}:${editedTime[1]||''} ${editedTime[3]||''}`

    return { outputDate, outputTime: outputTime.trim() }
  }

  return outputDate
}

export default changeDate

import * as React from 'react';
import { Navigate } from 'react-router-dom'
import token from '../utils/security/token'
import PropTypes from 'prop-types'
class ProtectedRoute extends React.Component {
  render() {
    const Component = this.props.component
    let isAuth = token.authenticate()

    return isAuth ? <Component /> : <Navigate to="/login" />
  }
}

ProtectedRoute.propTypes = {
  component: PropTypes.any,
}

export default ProtectedRoute

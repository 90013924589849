const date = new Date()
export default {
  clearAll: () => {
    localStorage.removeItem('cache')
  },
  setCache: (url, data) => {
    let cache = JSON.parse(localStorage.getItem('cache')) || {}
    cache[url] = { ...data, exp: Date.now() + 3600000 }
    localStorage.setItem('cache', JSON.stringify(cache))
  },
  setCacheV2: (url, data) => {
    let cache = JSON.parse(localStorage.getItem('cache')) || {}
    cache[url] = { data: data, exp: Date.now() + 3600000 }
    localStorage.setItem('cache', JSON.stringify(cache))
  },
  setCacheDay: (url, data) => {
    let cache = JSON.parse(localStorage.getItem('cache')) || {}
    cache[url] = {
      ...data,
      exp: new Date(date.getFullYear(), date.getMonth(), date.getDate()).setDate(
        new Date().getDate() + 1,
      ),
    }
    localStorage.setItem('cache', JSON.stringify(cache))
  },
  getCache: (url) => {
    let cache = JSON.parse(localStorage.getItem('cache')) || {}
    return cache[url]
  },
  getCacheV2: (url) => {
    let cache = JSON.parse(localStorage.getItem('cache')) || {}
    return cache[url]['data']
  },
  isValid: (url) => {
    let cache = JSON.parse(localStorage.getItem('cache')) || {}
    if (cache[url]?.exp > Date.now()) {
      return true
    }
    return false
  },
}
